import { API_URL } from "../../utils/Constant";
import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import ChartComponent from "../../components/chart/ChartComponent";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { Select, DatePicker } from "antd";
import { capitalizeAndRemoveUnderscores } from "../../utils/utils";
import moment from "moment";

const { RangePicker } = DatePicker;

function CompanyAnalytics() {
  const token = localStorage.getItem("access_token");
  const role = localStorage.getItem("user_role");
  const [top10Customers, setTop10Customers] = useState([]);
  const [agreementCount, setAgreementCount] = useState([]);
  const [teamProductivityData, setTeamProductivityData] = useState([]);

  const [paymentFrequency, setPaymentFrequency] = useState([]);

  const [productWiseSummary, setProductWiseSummary] = useState([]);
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 3000);
  }, []);

  useEffect(() => {
    const fetchMonthTop10Customers = async () => {
      // try {
      //   const response = await axios.get(
      //     API_URL + "/chart/month_wise_agreement/",
      //     config
      //   );
      //   setMonthWiseSummary(response.data.data);
      //   // window.location.reload(); // Update the state with the fetched company data
      // } catch (error) {}
    };

    fetchMonthTop10Customers();
  }, []);
  useEffect(() => {
    const fetchUserTop10Customers = async () => {
      // try {
      //   const response = await axios.get(
      //     API_URL + "/chart/product_wise_summary",
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //       params: {
      //         group_by: "created_by",
      //       },
      //     }
      //   );
      //   setUserWiseSummary(response.data.data);
      //   // window.location.reload(); // Update the state with the fetched company data
      // } catch (error) {}
    };

    fetchUserTop10Customers();
  }, []);
  const fetchProductWiseSummary = async () => {
    try {
      const response = await axios.get(
        API_URL + "/chart/get/product-wise-agreement-count/",
        config
      );
      setProductWiseSummary(response.data.data);
      // window.location.reload(); // Update the state with the fetched company data
    } catch (error) {}
  };

  const productNames = productWiseSummary?.map(
    (item) => item?.product_name ?? "Product1"
  );

  const productCount =
    productWiseSummary && productWiseSummary.map((item) => item.count);

  const maxDataValue = Math.max(...productCount);

  const yaxisOptions = {
    max: maxDataValue < 10 ? 10 : maxDataValue,
  };

  const productWiseData = {
    series: [
      {
        name: "Product Wise Summary",
        data: productCount,
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      plotOptions: {
        bar: {
          distributed: false, // this line is mandatory
          horizontal: false,
          endingShape: "rounded",
        },
      },
      colors: ["#A395DA", "#626993"],
      xaxis: {
        categories: productNames,
      },
      yaxis: yaxisOptions,
    },
  };

  const fetchTop10Customers = async (value) => {
    try {
      const response = await axios.get(
        API_URL + "/chart/get/top-ten-customer/",
        {
          headers: config.headers,
          params: {
            nature: value ? value : "customer",
          },
        }
      );
      setTop10Customers(response.data.data);
      // window.location.reload(); // Update the state with the fetched company data
    } catch (error) {}
  };

  const fetchAgreementCount = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        API_URL + "/chart/get/agreement-count/",
        {
          headers: config.headers,
          params: {
            start_date: startDate ?? startDate,
            end_date: endDate ?? endDate,
          },
        }
      );
      setAgreementCount(response.data);
      // window.location.reload(); // Update the state with the fetched company data
    } catch (error) {}
  };

  const statusNames =
    agreementCount &&
    agreementCount.map((item) => capitalizeAndRemoveUnderscores(item.status));
  const statusCount =
    agreementCount && agreementCount.map((item) => item.count);

  const maxCountValue = Math.max(...statusCount);

  const yaxisCountOptions = {
    max:
      maxCountValue < 10 ? 10 : Math.round(maxCountValue + maxCountValue / 2),
  };
  const agreementCountData = {
    series: [
      {
        name: "Status Wise Summary",
        data: statusCount,
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      plotOptions: {
        bar: {
          distributed: false, // this line is mandatory
          horizontal: false,
          endingShape: "rounded",
        },
      },
      colors: ["#A395DA", "#626993"],
      xaxis: {
        categories: statusNames,
      },
      yaxis: yaxisCountOptions,
    },
  };

  const fetchTeamProductivity = async (value) => {
    try {
      const response = await axios.get(API_URL + "/chart/team-productvity/", {
        headers: config.headers,
      });
      setTeamProductivityData(response.data.data);
      // window.location.reload(); // Update the state with the fetched company data
    } catch (error) {}
  };

  const name =
    teamProductivityData &&
    teamProductivityData.map((item) =>
      capitalizeAndRemoveUnderscores(item.department_name)
    );
  const count =
    teamProductivityData &&
    teamProductivityData.map((item) => ({
      ...item,
      days: parseInt(item.days, 10) || 0,
    }));
  const teamProductivityDataColumn = {
    series: [
      {
        name: "Productivity Summary",
        data: count,
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      plotOptions: {
        bar: {
          distributed: false, // this line is mandatory
          horizontal: false,
          endingShape: "rounded",
        },
      },
      colors: ["#A395DA", "#626993"],
      xaxis: {
        categories: name,
      },
      yaxis: yaxisOptions,
    },
  };

  const fetchPayemntFrequency = async () => {
    try {
      const response = await axios.get(
        API_URL + "/chart/get/payment-frequency-value/",
        config
      );
      setPaymentFrequency(response.data.data);
      // window.location.reload(); // Update the state with the fetched company data
    } catch (error) {}
  };

  const paymentFrequencyName =
    productWiseSummary &&
    paymentFrequency
      .filter((item) => item && item.payment_frequency)
      .map((item) => item.payment_frequency);

  const paymentValue =
    productWiseSummary &&
    paymentFrequency
      .filter((item) => item && item.payment_frequency)
      .map((item) => item.total_agreement_value);

  const maxpaymentValue = Math.max(...paymentValue);

  const yaxisPayemntOptions = {
    max: maxpaymentValue < 50000 ? 100000 : maxpaymentValue,
    title: {
      text: `${
        localStorage.getItem("currency") != null &&
        localStorage.getItem("currency")
          ? localStorage.getItem("currency")
          : ""
      }`,
    },
  };

  const payemntFrequencyData = {
    series: [
      {
        name: "Summary",
        data: paymentValue,
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      plotOptions: {
        bar: {
          distributed: false, // this line is mandatory
          horizontal: false,
          endingShape: "rounded",
        },
      },

      colors: ["#A395DA", "#626993"],
      xaxis: {
        categories: paymentFrequencyName,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                localStorage.getItem("currency") != null
                  ? localStorage.getItem("currency") + " "
                  : ""
              }` + val
            );
          },
        },
      },
      yaxis: yaxisPayemntOptions,
    },
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      nature: "",
    },
  };

  console.log(
    yaxisPayemntOptions,
    localStorage.getItem("currency") != null,
    "chart"
  );

  useEffect(() => {
    // Fetch user data from the API
    const fetchUsers = async () => {
      // try {
      //   const response = await axios.get(API_URL + "/company/get/", config);
      //   setIsloading(false);
      //   setCompanies(response.data); // Update the state with the fetched user data
      // } catch (error) {}
    };
    if (role && role.toLowerCase() === "superadmin") {
      fetchUsers();
    }
  }, []);

  const state = {
    series: [
      {
        name: "Desktops",
        data: [75, 90, 35, 21, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      plotOptions: {
        bar: {
          distributed: false, // this line is mandatory
          horizontal: false,
          endingShape: "rounded",
        },
      },
      colors: ["#A395DA", "#626993"],
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
    },
  };
  useEffect(() => {
    fetchProductWiseSummary();
    fetchTop10Customers();
    fetchPayemntFrequency();
    fetchAgreementCount();
    fetchTeamProductivity();
  }, []);

  const handleSelectChange = (value) => {
    fetchTop10Customers(value);
  };
  const customerNature = [
    { value: "customer", label: "Customer" },
    { value: "vendor", label: "Vendor" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleRangePickerChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    console.log(dateStrings);
  };
  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
      fetchAgreementCount(formattedStartDate, formattedEndDate);
    }
  }, [startDate, endDate]);

  const disabledDate = (current) => {
    const today = moment().startOf("day");
    const oneYearAgo = moment().subtract(1, "year").startOf("day");

    // Allow dates from today to one year ago (inclusive)
    return current && (current > today || current < oneYearAgo);
  };

  return (
    <>
      <div className="dashboard h-[100%] flex flex-row"></div>
      <div className="grid  grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="col-span-2  lg:col-span-2 gap-2">
          <div className="grid gap-4">
            <div className="row-span-1">
              <div className="grid grid-cols-2 gap-2">
                <div className="row-span-1  h-1/3">
                  <div className="">
                    <div className="bg-white rounded-t-lg">
                      <h1
                        className="pl-4 font-semibold"
                        style={{ fontSize: 18, paddingTop: "1%" }}
                      >
                        Productivity Summary
                      </h1>
                    </div>
                    <ChartComponent
                      chartData={{
                        options: teamProductivityDataColumn.options,
                        series: teamProductivityDataColumn.series,
                        additionalProps: { type: "bar", height: 300 },
                        rounded: "rounded-b-lg",
                      }}
                    />
                  </div>
                </div>

                <div className="row-span-1 h-7 ">
                  <div className="">
                    <div className="bg-white rounded-t-lg">
                      <h1
                        className="pl-4 font-semibold"
                        style={{ fontSize: 18, paddingTop: "1%" }}
                      >
                        Product wise Summary
                      </h1>
                    </div>
                    <ChartComponent
                      chartData={{
                        options: productWiseData.options,
                        series: productWiseData.series,
                        additionalProps: { type: "bar", height: 300 },
                        rounded: "rounded-b-lg",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row-span-1">
              <div className="grid grid-cols-2 gap-2">
                <div className="row-span-1 h-7 ">
                  <div className="">
                    <div className="bg-white rounded-t-lg">
                      <h1
                        className="pl-4 font-semibold"
                        style={{ fontSize: 18, paddingTop: "1%" }}
                      >
                        Summary of Payment Type
                      </h1>
                    </div>
                    <ChartComponent
                      chartData={{
                        options: payemntFrequencyData.options,
                        series: payemntFrequencyData.series,
                        additionalProps: { type: "bar", height: 340 },
                        rounded: "rounded-b-lg",
                      }}
                    />
                  </div>
                </div>

                <div className="row-span-1 ">
                  <div className="">
                    <div className="bg-white rounded-t-lg flex flex-col">
                      <h1
                        className="pl-4 font-semibold"
                        style={{ fontSize: 18, paddingTop: "1%" }}
                      >
                        Status Summary
                      </h1>
                      <RangePicker
                        onChange={handleRangePickerChange}
                        disabledDate={disabledDate}
                        style={{ width: "220px" }}
                        className="ml-2 mt-2"
                      />
                    </div>
                    <ChartComponent
                      chartData={{
                        options: agreementCountData.options,
                        series: agreementCountData.series,
                        additionalProps: { type: "bar", height: 300 },
                        rounded: "rounded-b-lg",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1 lg:col-span-1 bg-white rounded-md p-10">
          <div className="bg-white rounded-t-lg mb-6 flex flex-row">
            <h1
              className="font-semibold"
              style={{ fontSize: 18, paddingTop: "1%" }}
            >
              Top 10
            </h1>
            <span>
              <Select
                defaultValue={{ value: "customer", label: "Customer" }}
                options={customerNature}
                onChange={handleSelectChange}
                size="medium"
                style={{
                  width: "14em",
                  marginLeft: "9px",
                }}
                placeholder={"Select Nature"}
                allowClear
              />
            </span>
          </div>
          <div className="grid  grid-cols-1 gap-3">
            {top10Customers &&
              top10Customers.map((value, index) => {
                const completed = Math.ceil(
                  (parseInt(value.total_agreement_value) / 100000) * 100
                );
                return (
                  <ProgressBar
                    bgcolor={"#A395DA"}
                    completed={completed}
                    name={value.customer}
                    amount={value.total_agreement_value}
                    srNo={index + 1}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyAnalytics;
