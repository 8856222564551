import React, { useState } from "react";
import {
  Modal,
  Table,
  Button,
  Checkbox,
  InputNumber,
  Space,
  Radio,
  message,
  Input,
} from "antd";
import { limitWords } from "../../utils/utils";
import { Tooltip } from "antd";
import { MdPreview } from "react-icons/md";
import ClausesPreviewModal from "./clausePreviewModal";
import axios from "../../utils/axios";
import { API_URL } from "../../utils/Constant";
import EditClauseOrderModal from "./editClauseOrderModal";

const ClausesModal = ({
  visible,
  onCancel,
  clausesData,
  masterClausesData,
  okText,
  loading,
  handleChange,
  handleRowClick,
  // selectedRowKeys,
  // handleSequenceChange,
  // onSelectChange
  onSubmit,
  directSubmit,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [openAllClausePreview, setOpenAllClausePreview] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedMasterRowKeys, setSelectedMasterRowKeys] = useState([]);

  const [previewData, setPreviewData] = useState(1); // Initial sequence value
  const [sequenceMap, setSequenceMap] = useState({}); // Map to store sequence for each selected row
  const [masterSequenceMap, setMasterSequenceMap] = useState({}); // Map to store sequence for each selected row
  const [maxIndex, setMaxIndex] = useState(0);

  // const [previewData, setPreviewData] = useState();

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);

    const newSequenceMap = {};
    selectedRowKeys.forEach((id, index) => {
      newSequenceMap[id] = sequenceMap[id] || maxIndex + 1; // Use existing sequence or default to index + 1
      setMaxIndex(maxIndex + 1);
    });
    setSequenceMap(newSequenceMap);
  };
  

  const onMasterSelectChange = (selectedRowKeys) => {
    setSelectedMasterRowKeys(selectedRowKeys);

    setSelectedMasterRowKeys((prevSelectedMasterRowKeys) => {
      const newMasterSequencemap = {};
      prevSelectedMasterRowKeys.forEach((id, index) => {
        newMasterSequencemap[id] = masterSequenceMap[id] || maxIndex + 1; // Use existing sequence or default to index + 1
        setMaxIndex(maxIndex + 1);
      });

      setMasterSequenceMap(newMasterSequencemap);
      return selectedRowKeys; // Return the updated selectedRowKeys
    });
  };

  const handleSequenceChange = (id, sequence) => {
    const updatedSequenceMap = { ...sequenceMap, [id]: sequence };
    const sequenceValues = Object.values(updatedSequenceMap);
    const uniqueSequenceValues = new Set(sequenceValues);
    if (sequenceValues.length !== uniqueSequenceValues.size) {
      message.error("Error: Sequences must be unique.");
      return;
    }
  };
  const handleMasterSequenceChange = (id, sequence) => {
    const updatedMasterSequenceMap = { ...masterSequenceMap, [id]: sequence };
    const masterSequenceValues = Object.values(updatedMasterSequenceMap);
    const uniqueMasterSequenceValues = new Set(masterSequenceValues);
    if (masterSequenceValues.length !== uniqueMasterSequenceValues.size) {
      message.error("Error: Sequences must be unique.");
      return;
    }
  };

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedRowKeys(selectedRowKeys);

  //   // Log selected clause ID and sequence
  //   console.log("Selected clauses:");
  //   selectedRowKeys.forEach(id => {
  //     console.log(`ID: ${id}, Sequence: ${sequence}`);
  //     setSequence(sequence + 1); // Increment sequence
  //   });
  // };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const masterRowSelection = {
    selectedMasterRowKeys,
    onChange: onMasterSelectChange,
  };
  // const sequenceArray = Object.keys(sequenceMap).map((clause_id) => ({
  //   clause_id: parseInt(clause_id),
  //   clause_order: sequenceMap[clause_id],
  // }));
  const sequenceArray = Object.keys(sequenceMap).map((clause_id) => {
    const foundItem = clausesData.find(item => item.id === parseInt(clause_id));
    return {
      clause_id: parseInt(clause_id),
      clause_name: foundItem ? foundItem.clause_name : null, // Adding clause_name property
      clause_order: sequenceMap[clause_id],
    };
  });
  
  const masterSequenceArray = Object.keys(masterSequenceMap).map((clause_id) => {
    const foundItem = masterClausesData.find(item => item.id === parseInt(clause_id));
    return {
      clause_id: parseInt(clause_id),
      clause_name: foundItem ? foundItem.clause_name : null, // Adding clause_name property
      clause_order: masterSequenceMap[clause_id],
    }
  });

  const fetchPrewviewData = async (clause_id) => {
    try {
      // setIsLoading(true);
      setPreviewLoading(true);
      const response = await axios.get(API_URL + `/contract/clauser-preview/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
        params: {
          clause_id: clause_id,
        },
      });
      setPreviewData(response.data.data);
      setPreviewLoading(false);
    } catch (error) {
      // setIsLoading(false);
      setPreviewLoading(false);
    }
  };

  const masterClauseColumns = [
    // {
    //   title: "Sequence",
    //   dataIndex: "sequence",
    //   key: "sequence",
    //   render: (text, record) => (
    //     <>
    //       {selectedMasterRowKeys.includes(record.id) && (
    //         <InputNumber
    //           min={1}
    //           value={masterSequenceMap[record.id]}
    //           onChange={(value) => handleMasterSequenceChange(record.id, value)}
    //         />
    //       )}
    //     </>
    //   ),
    // },

    {
      title: "Clause name",
      dataIndex: "clause_name",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Implementation Instances",
      dataIndex: "implementation_instances",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    // {
    //   title: "Serial Number",
    //   dataIndex: "serial_number",
    //   render: (text, record) => (
    //     <Tooltip title={text}>
    //       <div
    //         style={{
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           whiteSpace: "nowrap",
    //         }}
    //       >
    //         {limitWords(text, 6)}
    //       </div>
    //     </Tooltip>
    //   ),
    // },

    // {
    //   title: "Download",
    //   dataIndex: "clause_file",
    //   render: (_, record) => (
    //     <Space>
    //       <Button
    //         type="primary"
    //         onClick={() => handlePdfDownload(record.clause_file)}
    //       >
    //         <FaDownload />
    //       </Button>
    //     </Space>
    //   ),
    // },
    {
      title: "Preview",
      dataIndex: "template_file",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setShowPreviewModal(true);
              fetchPrewviewData(record.id);
            }}
          >
            <MdPreview />
          </Button>
          <ClausesPreviewModal
            visible={showPreviewModal}
            onCancel={onCancelPreviewModal}
            data={previewData && previewData}
            loading={previewLoading}
          />
        </Space>
      ),
    },
  ];

  const clauseColumns = [
    // {
    //   title: "Sequence",
    //   dataIndex: "sequence",
    //   key: "sequence",
    //   render: (text, record) => (
    //     <>
    //       {selectedRowKeys.includes(record.id) && (
    //         <InputNumber
    //           min={1}
    //           value={sequenceMap[record.id]}
    //           onChange={(value) => handleSequenceChange(record.id, value)}
    //         />
    //       )}
    //     </>
    //   ),
    // },

    {
      title: "Clause name",
      dataIndex: "clause_name",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Implementation Instances",
      dataIndex: "implementation_instances",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    // {
    //   title: "Serial Number",
    //   dataIndex: "serial_number",
    //   render: (text, record) => (
    //     <Tooltip title={text}>
    //       <div
    //         style={{
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           whiteSpace: "nowrap",
    //         }}
    //       >
    //         {limitWords(text, 6)}
    //       </div>
    //     </Tooltip>
    //   ),
    // },

    // {
    //   title: "Download",
    //   dataIndex: "clause_file",
    //   render: (_, record) => (
    //     <Space>
    //       <Button
    //         type="primary"
    //         onClick={() => handlePdfDownload(record.clause_file)}
    //       >
    //         <FaDownload />
    //       </Button>
    //     </Space>
    //   ),
    // },
    {
      title: "Preview",
      dataIndex: "template_file",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setShowPreviewModal(true);
              fetchPrewviewData(record.id);
            }}
          >
            <MdPreview />
          </Button>
          <ClausesPreviewModal
            visible={showPreviewModal}
            onCancel={onCancelPreviewModal}
            data={previewData && previewData}
          />
        </Space>
      ),
    },
  ];
  //   const data = [
  //     { id: 1, templateName: 'Template 1' },
  //     { id: 2, templateName: 'Template 2' },
  //     // Add more templates as needed
  //   ];
  const [selectedData, setSelectedData] = useState(null);

  const handleCheckboxChange = (record) => {
    setSelectedId(selectedId === record.id ? null : record.id);
    setSelectedData(selectedId === record.id ? null : record);
  };

  // const handleSave = () => {
  //   // Do something with the selected ID
  //   // onSelect(selectedData); // Pass the selected data to the callback
  //   onSubmit([...sequenceArray, ...masterSequenceArray]);

  //   // Close the modal

  //   onCancel();
  // };
  const handlePreviewAndSubmit = () => {
    setOpenAllClausePreview(true);
  };
  const onCancelPreviewModal = () => {
    setShowPreviewModal(false);
    setPreviewData(null);
  };
  const allClauses = [...sequenceArray, ...masterSequenceArray];

  const handlePreviewSubmit = (data) => {
    // Do something with the selected ID
    // onSelect(selectedData); // Pass the selected data to the callback

    onSubmit(data);

    // Close the modal

    onCancel();
  };
  const handlePreviewModalSubmit = (data) => {
    // setFinalData(data);
    setOpenAllClausePreview(false);
    onSubmit(data);
    // onCancel(); // Close the modal after successful submission
    setTimeout(() => {
      onCancel();
    }, 1000);
  };

  const handleSubmit = (allClauses) => {
    console.log(allClauses);
    // setFinalAllClauses(data);
    onSubmit(allClauses);
    // onCancel(); // Close the modal after successful submission
    onCancel();
  };
  // if(finalData){
  //   onSubmit(finalData);

  //   // Close the modal

  //   onCancel();
  // }

  return (
    <Modal
      visible={visible}
      title="Select one or multiple Clauses"
      onCancel={onCancel}
      className="clause-modal"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        directSubmit ? (
          <Button
            key="save"
            type="primary"
            onClick={() => {
              // const data = allClauses.map((clause) => clause.clause_id);
              onSubmit(allClauses && allClauses);
              // onCancel();
            }}
            loading={loading && loading}
          >
            {okText ? okText : "Submit"}
          </Button>
        ) : (
          <Button
            key="save"
            type="primary"
            onClick={handlePreviewAndSubmit}
            loading={loading && loading}
            disabled={Object.keys(sequenceMap).length === 0 &&  Object.keys(masterSequenceMap).length === 0}
          >
            {okText ? okText : "Preview"}
          </Button>
        ),
        <EditClauseOrderModal
          data={allClauses && allClauses}
          visible={openAllClausePreview}
          onCancel={() => setOpenAllClausePreview(false)}
          onSubmit={handlePreviewModalSubmit}
          loading={loading}
          clausesData={clausesData}
          masterClausesData={masterClausesData}
          
        />,
      ]}
    >
      <h1>Clauses</h1>
      <Table
        columns={clauseColumns}
        dataSource={clausesData}
        rowKey="id"
        pagination={true}
        scroll={{ x: "max-content" }}
        rowSelection={rowSelection}
      />
      <h1 className="mt-3">Master Clauses</h1>

      <Table
        columns={masterClauseColumns}
        dataSource={masterClausesData}
        rowSelection={masterRowSelection}
        rowKey="id"
        pagination={true}
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};

export default ClausesModal;
