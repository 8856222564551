import React from "react";

function SuccessButton({ link, text, onClick }) {
  return (
    // <div className='bg-green-900 text-center mx-14 h-8 border-red-900 rounded-md text-white cursor-pointer'><h1 className='text-xl'>Click</h1></div>
    // <Button color="green">color green</Button>
    <button
      type="button"
      className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
      data-te-ripple-init
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default SuccessButton;
