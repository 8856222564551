import React from "react";
import { Input } from "antd";
import { IoSearch } from "react-icons/io5";
const { Search } = Input;


const CustomSearch = ({ onSearch }) => {


  const handleSearch = (value, event) => {
    event.preventDefault(); // Prevent the default behavior of the search event
    onSearch(value);
  };

  return (

    <Search
      placeholder="Search..."
      onSearch={handleSearch}
      enterButton={<IoSearch />}
      allowClear
      />
  );
};

export default CustomSearch;

