import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    // You can also log the error to an error reporting service here
    // or perform any other error handling operations
  }

  render() {
    if (this.state.hasError) {
      // Custom error UI
      return (
        <div>
          <h2>Something went wrong</h2>
          <p>We apologize for the inconvenience. Please try again later.</p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary>Error details</summary>
            <p>{this.state.error && this.state.error.toString()}</p>
            <p>{this.state.errorInfo.componentStack}</p>
          </details>
        </div>
      );
    }

    // Render the children components if no error occurred
    return this.props.children;
  }
}

export default ErrorBoundary;
