import React from "react";
import SuccessButton from "../../components/button/SuccessButton";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Modal, App, Form } from "antd";
import { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import axios from '../../utils/axios';
import { API_URL } from "../../utils/Constant";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const data = [
  {
    key: "1",
    name: "John Brown",
    email: "hello@gmail.com",
    age: 32,
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Joe Black",
    age: 42,
    address: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Jim Green",
    age: 32,
    address: "Sydney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Jim Red",
    age: 32,
    address: "London No. 2 Lake Park",
  },
];

function ActiveUsers() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [loading, setIsloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userId, setUserId] = useState([]);
  const [modalText, setModalText] = useState(
    "Are you sure you want to Deactivate this user?"
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const [form] = Form.useForm();

  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page_size: 100,
      is_active: "True",
    },
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(API_URL + "/user/get/", config);
      setIsloading(false);
      setUsers(response.data); // Update the state with the fetched user data
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // Fetch user data from the API
    setIsloading(true);
    fetchUsers();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "S. No.",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`${record.id}`}>
          <button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View details
          </button>
        </Link>
      ),
    },
    {
      title: "User's Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "User's Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department) => (department ? department.name : ""),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Profile",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Activation Date",
      dataIndex: "created_at",
      key: "created_at",
      // render: (date) => formatIndianDate(date),
    },
    {
      title: "Deactivate",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <>
          <Space>
            <button
              onClick={() => showModal(record.id)}
              type="submit"
              className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-red-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
              Deactivate
            </button>
          </Space>
        </>
      ),
    },
    {
      title: "Assign Agreement",
      dataIndex: "age",
      key: "age",
      render: (text, record) => (
        <>
          <SuccessButton text="Click" />
        </>
      ),
    },
  ];

  const userWithIds = users
    ? users.map((item, index) => ({
        ...item,
        index: index + 1,
        created_at: new Date(item.created_at).toLocaleDateString(),
      }))
    : [];

  const showModal = (userId) => {
    setUserId(userId);
    setOpen(true);
  };
  const handleSubmit = (values) => {
    axios
      .put(
        `${API_URL}/user/${userId}/active/`,
        { active: false, reason: values.reason },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // Redirect to login page
          // window.location.href = "/admin/active_users";
          fetchUsers();
          form.resetFields();
        } else {
          throw new Error("Error updating user status.");
        }
      })
      .catch((error) => {
        // Display error message
        console.error(error.message);
      });
  };
  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  const showPagination = users && users >= 10;

  return (
    <>
      {loading ? (
        <div className="text-center top-3 left-3">
          <LoadingOutlined style={{ fontSize: "5rem" }} />
        </div>
      ) : (
        <Table
          bordered
          dataSource={userWithIds}
          columns={columns}
          className="overflow-y-scroll"
          // scroll={{
          //   y: 530,
          // }}
          scroll={{ x: "max-content" }}
        />
      )}
      <Modal
        title="Deactivate"
        open={open}
        onOk={() => handleOk(userId)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {/* <p>{modalText}</p> */}
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "Please enter deactivation reason.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ActiveUsers;
