import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from '../../utils/axios';
import { API_URL } from "../../utils/Constant";
import { Link } from "react-router-dom";
// import { EditOutlined } from "@mui/icons-material";
import { EditOutlined } from "@ant-design/icons";
import { Badge, Descriptions, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { IoMdArrowRoundBack } from "react-icons/io";


function CompanyDetail() {
  const token = localStorage.getItem("access_token");
  const params = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [company, setCompany] = useState();
  const [editMode, setEditMode] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editedCompany, setEditedCompany] = useState(
    company ? { ...company } : {}
  );
  const user_role = localStorage.getItem("user_role");

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setEditedCompany({ ...company });
  };

  const handleSave = () => {
    axios
      .put(API_URL + `/user/${params.pk}/update/`, editedCompany)
      .then((response) => {
        // Handle successful response
        setEditMode(false);
        setSuccess(true);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating user details:", error);
      });

    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    // Fetch company data from the API
    setIsloading(true);
    const fetchCompanys = async () => {
      try {
        const response = await axios.get(
          API_URL + `/company/${params.pk}/get/`,
          config
        );
        setIsloading(false);
        setCompany(response.data);
        setEditedCompany(response.data);
        // window.location.reload(); // Update the state with the fetched company data
      } catch (error) {}
    };
    fetchCompanys();
  }, [editMode]);

  useEffect(() => {
    localStorage.setItem("gyrwmnqijpkl", "xvzndrpkloqo9");
  });

  return (
    <>
      {company ? (
        <>
          <div className="py-3 flex user-heading">
            <Link to={{ pathname: "/admin/settings/" }}>
              <IoMdArrowRoundBack size="40px" />
            </Link>
            <h1 className="font-bold text-[22px]">Company Info</h1>
            <div className="flex user-info-heading">
              {!editMode && (
                <>
                  {(user_role && user_role.toLowerCase() === "admin") ||
                  user_role.toLowerCase() === "superadmin" ? (
                    <span>
                      {/* <EditOutlined onClick={handleEdit} /> */} 
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          <Descriptions title="" layout="vertical" bordered>
            <Descriptions.Item label="Company Name" span={2}>
              {editMode && editedCompany ? (
                <Input
                  name="name"
                  value={editedCompany.name}
                  onChange={handleInputChange}
                />
              ) : (
                company.name
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Active Status" span={2}>
              {!company.is_active ? (
                <Badge color="red" status="" text="Inactive" />
              ) : (
                <Badge status="success" text="Running" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Admin's Name">
              {editMode && editedCompany ? (
                <Input
                  name="name_of_admin"
                  value={editedCompany.name_of_admin}
                  onChange={handleInputChange}
                />
              ) : (
                company.name_of_admin
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Admin's Contact">
              {editMode && editedCompany ? (
                <Input
                  name="admin_contact_number"
                  value={editedCompany.admin_contact_number}
                  onChange={handleInputChange}
                />
              ) : (
                company.admin_contact_number
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Admin's Email">
              {editMode && editedCompany ? (
                <Input
                  name="admin_email"
                  value={editedCompany.admin_email}
                  onChange={handleInputChange}
                />
              ) : (
                company.admin_email
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Company License No.">
              {editMode && editedCompany ? (
                <Input
                  name="cin"
                  value={editedCompany.cin}
                  onChange={handleInputChange}
                />
              ) : (
                company.cin
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Contact">
              {editMode && editedCompany ? (
                <Input
                  name="contact"
                  value={editedCompany.contact}
                  onChange={handleInputChange}
                />
              ) : (
                company.contact
              )}
            </Descriptions.Item>
            <Descriptions.Item label="TAX License No.">
              {editMode && editedCompany ? (
                <Input
                  name="gst_number"
                  value={editedCompany.gst_number}
                  onChange={handleInputChange}
                />
              ) : (
                company.gst_number
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Domain">
              {editMode && editedCompany ? (
                <Input
                  name="domain"
                  value={editedCompany.domain}
                  onChange={handleInputChange}
                />
              ) : (
                company.domain
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Number of Licence">
              {editMode && editedCompany ? (
                <Input
                  name="number_of_licence"
                  value={editedCompany.number_of_licence}
                  onChange={handleInputChange}
                />
              ) : (
                company.number_of_licence
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Total Users">
              {editMode && editedCompany ? (
                <Input
                  name="user_count"
                  value={editedCompany.user_count}
                  onChange={handleInputChange}
                />
              ) : (
                company.user_count
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Valid From" span={2}>
              {new Date(company.valid_from).toLocaleDateString()}
            </Descriptions.Item>
            <Descriptions.Item label="Valid To" span={2}>
              {new Date(company.valid_to).toLocaleDateString()}
            </Descriptions.Item>
          </Descriptions>
          {editMode && editedCompany && (
            <div className="mt-12 flex">
              <Button onClick={handleCancel} className="">
                Cancel
              </Button>
              <Button type="primary" onClick={handleSave} className="ml-12">
                Save
              </Button>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CompanyDetail;
