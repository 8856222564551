import React, { useState } from "react";
import { Button, Upload, message, Form, Input } from "antd";
import axios from "../../../utils/axios";
import { API_URL } from "../../../utils/Constant";
import { UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
message.config({
  maxCount: 1,
});

function Index() {
  const params = new URLSearchParams(document.location.search);
  localStorage.clear();
  const token = params.get("token");
  const agreementType = params.get("agreement_type");

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [fileName, setFilename] = useState();
  const [otherAttachment, setOtherAttachment] = useState();

  const agreementDetails = {
    title: agreementType && agreementType,
    description: "Please click respective TAB for respective actions.",
  };

  const config = {
    headers: {
      Authorization: `${token}`,
      "Content-type": "multipart/form-data",
    },
  };
  const handleDownload = async () => {
    try {
      const response = await axios.get(
        API_URL + "/contract/manage-outgoing-agreement/",
        config
      );
      window.open(`${response.data.data}`, "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    setUploading(true);
    const response = await axios.put(
      API_URL + "/contract/manage-outgoing-agreement/",
      formData,
      config
    );
    if (response.status === 200) {
      setFileList([]);
      // message.success("The document has been successfully shared with Legal Team");
      message.success(response.data.message);
      console.log(response.data);
      console.log(response);

      setUploading(false);
    } else {
      message.error("Upload failed.");
      setUploading(false);
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const [remarks, setRemarks] = useState("");
  const [form] = Form.useForm();

  const handleFormSubmit = async (values) => {
    let formData = {
      ...values,
      agreement_file: fileName && fileName,
      agreement_attachment: otherAttachment && otherAttachment,
      remarks: remarks,
    };
    try {
      setUploading(true);
      const response = await axios.put(
        API_URL + "/contract/manage-outgoing-agreement/",
        formData,
        config
      );
      if (response.status === 200) {
        setFileList([]);
        message.success(response.data.message);
        setUploading(false);
      } else {
        message.error("Upload failed.");
        setUploading(false);
      }
    } catch (error) {
      message.error(error.response.data.error);
      console.log("asdasdasdasda");
      setUploading(false);
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h2 className="text-2xl font-bold text-blue-600">
        {agreementDetails.title}
      </h2>
      <p className="mt-4 text-lg text-gray-700">
        {agreementDetails.description}
      </p>

      {/* Token information */}

      {/* Action buttons */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "10px",
          marginTop: "10px",
        }}
      >
        <div className="mt-5">
          <Button
            type="primary"
            onClick={() => handleDownload()}
            style={{ margin: "10px" }}
          >
            Download Agreement
          </Button>
        </div>

        {/* <div>
          <Upload {...props} showUploadList={true}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          {fileList.length === 0 ? (
            ""
          ) : (
            <Button
              type="submit"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{
                marginTop: 16,
              }}
            >
              {uploading ? "Sending..." : "Send"}
            </Button>
          )}

          <Button
            type="submit"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              marginTop: 16,
            }}
          >
            {uploading ? "Sending..." : "Send"}
          </Button>
        </div> */}
        <div>
          <Form
            form={form}
            onFinish={handleFormSubmit}
            layout="vertical"
            className="p-4"
          >
            {/* <Form.Item
              label="Select File"
              name="selectFile"
              className="mb-4 attachment-input"
              rules={[{ required: true, message: "Please select a file" }]}
            ></Form.Item> */}
            <Form.Item
              label="Agreement File"
              name="agreement_file"
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}
              className="mt-2"
            >
              <Input
                type="file"
                onChange={(e) => setFilename(e.target.files[0])}
                className="form-control"
              ></Input>
            </Form.Item>
            <Form.Item
              label="Other Attachment"
              name="agreement_attachment"
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}
              className="mt-2"
            >
              <Input
                type="file"
                onChange={(e) => setOtherAttachment(e.target.files[0])}
                className="form-control"
              ></Input>
            </Form.Item>
            <Form.Item label="Remarks" name="remarks" className="mb-4">
              <TextArea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Enter Remarks"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={uploading}>
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Index;
