// export const API_URL = process.env.REACT_APP_API_URL;
export const PDF_URL = process.env.REACT_APP_PDF_URL;
export const documentServerURL = process.env.REACT_APP_DOCUMENT_SERVER_URL;
export const syncFusionLicense = process.env.REACT_APP_SYNC_FUSION_LICENSE;
export const syncServiceUrl = process.env.REACT_APP_SYNC_SERVICE_URL;


export const API_URL = (() => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.NODE_ENV === "prod") {
    return process.env.REACT_APP_API_URL;
  } else {
    return process.env.REACT_APP_API_URL;
  }
})();

export const TOKEN = localStorage.getItem("access_token");

export const formatIndianDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  if (isoDate) {
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
};

export const config = {
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
};
export const agreementOptions = [
  { value: "Addendum Agreement", label: "Addendum Agreement" },
  { value: "Agreement to Sale", label: "Agreement to Sale" },
  {
    value: "Brand Store and Technology Integration Agreement",
    label: "Brand Store and Technology Integration Agreement",
  },
  { value: "Cloud Agreement", label: "Cloud Agreement" },
  { value: "Code of Conduct", label: "Code of Conduct" },
  { value: "Collaboration Agreement", label: "Collaboration Agreement" },
  {
    value: "Commercial Distribution Agreement",
    label: "Commercial Distribution Agreement",
  },
  { value: "Consultant Agreement", label: "Consultant Agreement" },
  { value: "Contractor Agreement", label: "Contractor Agreement" },
  {
    value: "Corporate Research Agreement",
    label: "Corporate Research Agreement",
  },
  { value: "Data Processing Agreement", label: "Data Processing Agreement" },
  { value: "Data Security Agreement", label: "Data Security Agreement" },
  { value: "Declaration Letter", label: "Declaration Letter" },
  { value: "Distribution Agreement", label: "Distribution Agreement" },
  { value: "Eco Partner Agreement", label: "Eco Partner Agreement" },
  { value: "Engagement Letter", label: "Engagement Letter" },
  { value: "Freelance Writer Agreement", label: "Freelance Writer Agreement" },
  {
    value: "General Employment Agreement",
    label: "General Employment Agreement",
  },
  {
    value: "General Terms and Conditions",
    label: "General Terms and Conditions",
  },
  { value: "Joint Venture Agreement", label: "Joint Venture Agreement" },
  { value: "Lease Agreement", label: "Lease Agreement" },
  { value: "License Agreement", label: "License Agreement" },
  { value: "Loan Agreement", label: "Loan Agreement" },
  { value: "Management Agreement", label: "Management Agreement" },
  { value: "Master Service Agreement", label: "Master Service Agreement" },
  {
    value: "Memorandum of Understanding",
    label: "Memorandum of Understanding",
  },
  { value: "Non-Disclosure Agreement", label: "Non-Disclosure Agreement" },
  {
    value: "Operation and Management Agreement",
    label: "Operation and Management Agreement",
  },
  { value: "Partnership Agreement", label: "Partnership Agreement" },
  {
    value: "Professional Service Agreement",
    label: "Professional Service Agreement",
  },
  { value: "Purchase Order", label: "Purchase Order" },
  { value: "Referral Agreement", label: "Referral Agreement" },
  { value: "Reseller Agreement", label: "Reseller Agreement" },
  { value: "SaaS Agreement", label: "SaaS Agreement" },
  { value: "Scope of Work", label: "Scope of Work" },
  { value: "Security Service Agreement", label: "Security Service Agreement" },
  { value: "Service Agreement", label: "Service Agreement" },
  { value: "Shareholders’ Agreement", label: "Shareholders’ Agreement" },
  { value: "Share Purchase Agreement", label: "Share Purchase Agreement" },
  {
    value: "Share Subscription Agreement",
    label: "Share Subscription Agreement",
  },
  { value: "Share Transfer Agreement", label: "Share Transfer Agreement" },
  { value: "Sponsorship Agreement", label: "Sponsorship Agreement" },
  { value: "Supply Agreement", label: "Supply Agreement" },
  { value: "Vendor Agreement", label: "Vendor Agreement" },
  { value: "Work Order", label: "Work Order" },
];

export const agreementStatus = [
  { value: "FRESH_REQUEST", label: "Fresh Request" },
  { value: "UNDER_NEGOTIATION", label: "Under Negotiation" },
  { value: "UNDER_EXECUTION", label: "Under Execution" },
  { value: "EXECUTED", label: "Executed" },
  { value: "TRANSACTION_CLOSED", label: "Transaction Closed" },
];

export const invoiceFrequencyOptions = [
  { value: "One Time", label: "One Time" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Half Yearly", label: "Half Yearly" },
  { value: "Anually", label: "Anually" },
  { value: "Event Based", label: "Event Based" },
];
