import React, { useState, useEffect } from "react";
import { Table, Tooltip, Select, Spin, Checkbox, Switch } from "antd";
import axios from "../../utils/axios";
import { API_URL } from "../../utils/Constant";
import { Link } from "react-router-dom";
import { formatIndianDate } from "../../utils/Constant";
import { LoadingOutlined } from "@ant-design/icons";
import ButtonComp from "../../components/button/ButtonComp";
import { capitalizeAndRemoveUnderscores, limitWords } from "../../utils/utils";
import CustomSearch from "../../components/search/CustomSearch";
import { agreementStatus } from "../../utils/Constant";
import ViewDetail from "../../components/button/ViewDetail";
import { useNavigate } from "react-router-dom";
import { IoMailUnreadOutline } from "react-icons/io5";
import EditorSwitch from "../../components/editorSwitch";

function AllRequests() {
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");
  const [isLoading, setIsloading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isEditor, setIsEditor] = useState(true); // Default state is true
  const [selectedStatus, setSelectedStatus] = useState(); // Default state is true
  const [readIcon, setReadIcon] = useState(false); // Default state is true


  let [request, setRequest] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page_size: 100,
      search_keyword: "",
      status: "",
    },
  };
  const handleRowSelectionChange = (selectedKeys) => {
    console.log(selectedKeys);
    setSelectedRowKeys(selectedKeys);
  };
  // const handleMarkUnread = () => {
  //   const updatedDataSource = dataSource.map((record) => {
  //     if (selectedRowKeys.includes(record.id) && record.status === "read") {
  //       return { ...record, is_read: false };
  //     }
  //     return record;
  //   });
  //   setDataSource(updatedDataSource);
  // };

  const fetchContracts = async (selectedStatus, isEditor) => {
    try {
      setIsloading(true);

      if (selectedStatus) {
        config.params.status = selectedStatus;
      }
      if (isEditor) {
        config.params.is_editor = isEditor;
      }
      const response = await axios.get(API_URL + "/contract/get/", config);
      setIsloading(false);
      setRequest(response.data); // Update the state with the fetched user data
    } catch (error) {}
  };

  const handleSwitchChange = (checked) => {
    setIsEditor(checked);
    fetchContracts(selectedStatus, checked);
    // Now, you can trigger your API call here with the updated value of isEditor
    // Example API call:
    // fetchData(checked); // Pass the checked value to your API call function
  };

  useEffect(() => {
    // Fetch user data from the API
    setIsloading(true);
    fetchContracts();
  }, []);

  const handleSearch = (value) => {
    config.params.search_keyword = value;
    setIsloading(true);
    fetchContracts();
  };
  const handleViewDetails = async (record) => {
    const configNew = { ...config };
    delete configNew.params;
    if (!record.is_read) {
      const response = await axios.put(
        API_URL + "/contract/update-read-or-unread-agreement/",
        {
          agreement_ids: [record.id],
          read_status: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
    }
    navigate(`/admin/contracts/${record.id}`);
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      // Select all rows
      const allRowKeys = request.map((item) => item.id);
      setSelectedRowKeys(allRowKeys);
    } else {
      // Deselect all rows
      setSelectedRowKeys([]);
    }
  };

  const columns = [
    // {
    //   title: "Select All",
    //   dataIndex: "selectAll",
    //   render: () => (
    //     <Checkbox
    //       indeterminate={
    //         selectedRowKeys.length > 0 && selectedRowKeys.length < request.length
    //       }
    //       checked={selectedRowKeys.length === request.length}
    //       onChange={handleSelectAllChange}
    //     />
    //   ),
    // },
    {
      title: (
        <Checkbox
          indeterminate={
            selectedRowKeys.length > 0 &&
            selectedRowKeys.length < request.length
          }
          checked={selectedRowKeys.length === request.length}
          onChange={handleSelectAllChange}
        />
      ),
      dataIndex: "checkbox",
      render: (_, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowKeys([...selectedRowKeys, record.id]);
            } else {
              setSelectedRowKeys(
                selectedRowKeys.filter((key) => key !== record.id)
              );
            }
          }}
        />
      ),
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      render: (userName, record) => (
        <span style={{ fontWeight: record.is_read ? "normal" : "bold" }}>
          {userName}
        </span>
      ),
    },
    {
      title: (
        <div>
          Date of Raising <br />
          (dd-mm-yyyy)
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      render: (date, record) => (
        <span style={{ fontWeight: record.is_read ? "normal" : "bold" }}>
          {formatIndianDate(date)}
        </span>
      ),
    },
    {
      title: "Agreement Type",
      dataIndex: "agreement_type",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: record.is_read ? "normal" : "bold",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Partner Name",
      dataIndex: "name_of_client",
      render: (text, record) => (
        <span style={{ fontWeight: record.is_read ? "normal" : "bold" }}>
          {text && text.charAt(0).toUpperCase() + text.slice(1)}
        </span>
      ),
    },
    {
      title: "Nature",
      dataIndex: "nature",
      render: (text, record) => (
        <span style={{ fontWeight: record.is_read ? "normal" : "bold" }}>
          {text && text.charAt(0).toUpperCase() + text.slice(1)}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span style={{ fontWeight: record.is_read ? "normal" : "bold" }}>
          {capitalizeAndRemoveUnderscores(text)}
        </span>
      ),
    },
    {
      title: "Remarks(if any)",
      dataIndex: "remarks",
      ellipsis: true, // Enable ellipsis for the column
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: record.is_read ? "normal" : "bold",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        // <Link to={`${record.id}`} >
        <ViewDetail onClick={() => handleViewDetails(record)} />
        // </Link>
      ),
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {};
  const showExtraFieldModal = () => {};
  const handleSelectChange = (value) => {
    setIsloading(true);
    fetchContracts(value);
    setSelectedStatus(value);
  };
  const handleIconClick = async () => {
    try {
      const response = await axios.put(
        API_URL + "/contract/update-read-or-unread-agreement/",
        {
          // agreement_id: JSON.stringify(selectedRowKeys),
          agreement_ids: selectedRowKeys,
          read_status: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        fetchContracts();
        setSelectedRowKeys([]);
      }
    } catch (error) {
      setSelectedRowKeys([]);
    }
  };

  return (
    <>
      <div>
        <div className=" text-black pb-3 px-2 flex items-center justify-between">
          <div className="flex flex-row justify-center align-middle items-center">
            <span>
              <h1
                className="mt-1 text-lg font-medium "
                style={{
                  fontWeight: "700",
                  fontFamily: "'Inter', sans-serif",

                  fontSize: "30px",
                }}
              >
                Contracts
              </h1>
            </span>
            <div className="ml-4 mr-2 w-4">
              {selectedRowKeys.length > 0 ? (
                <Tooltip title="Unread">
                  <IoMailUnreadOutline
                    className="text-2xl transform hover:scale-110 transition-transform duration-300"
                    onClick={handleIconClick}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <div className="pl-4">
              <CustomSearch onSearch={handleSearch} />
            </div>
            <div className="pl-4">
              <Select
                options={agreementStatus}
                onChange={handleSelectChange}
                size="medium"
                style={{
                  width: "14em",
                }}
                placeholder={"Select Status"}
                allowClear
              />
              {/* <Switch
                checkedChildren="Viewer"
                unCheckedChildren="Editor"
                defaultChecked
                className="text-black ml-2 bg-slate-500"
                onChange={handleSwitchChange}
                loading={isLoading}
              /> */}
              <EditorSwitch
                isLoading={isLoading}
                handleSwitchChange={handleSwitchChange}
              />
            </div>
          </div>
          <Link to="/admin/create_request">
            <ButtonComp
              text="Raise New Request"
              bgColor="#4E36A3"
              textColor="white"
              onClick={showExtraFieldModal}
            />
          </Link>
        </div>
        
        <Spin spinning={isLoading} tip="Loading...">
          <Table
            // rowSelection={{
            //   selectedRowKeys,
            //   key:"document_id",
            //   onChange: handleRowSelectionChange,
            // }}
            columns={columns}
            dataSource={request}
            onChange={onChange}
            scroll={{ x: "max-content" }}
          />
        </Spin>
      </div>
    </>
  );
}

export default AllRequests;
