import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Spin,
  Modal,
  Form,
  Input,
  Row,
  Col,
  message,
  Tooltip
} from "antd";
import { API_URL } from "../../utils/Constant";
import axios from "../../utils/axios";
import { handlePdfDownload } from "../../utils/utils";
import { FaDownload } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { limitWords } from "../../utils/utils";

function MasterTemplate() {
  const [form] = Form.useForm();
  const [templateData, setTemplateData] = useState([]);
  const [masterTemplateData, setMasterTemplateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFilename] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    try {
      setIsFormLoading(true);

      const response = await axios.post(
        API_URL + `/contract/master-template/`,
        { ...values, template_file: fileName && fileName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        message.success(response.data.message);
        fetchTemplates()
        setIsModalOpen(false);
        setIsFormLoading(false);
        form.resetFields();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsModalOpen(false);
      form.resetFields();
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setIsFormLoading(false);
    form.resetFields();
  };

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(API_URL + `/contract/master-template/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      setTemplateData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchMasterTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(API_URL + `/contract/get-master-template/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      setMasterTemplateData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchMasterTemplates();
    fetchTemplates();
  }, []);

  const deleteTemplate = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(API_URL + `/contract/master-template/`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
        params:{
          master_template_id: id
        }
      });
      setIsLoading(false);
      console.log(response)
      console.log(response.status)
      message.success("Template deleted successfully");
        fetchTemplates();
    } catch (error) {
      setIsLoading(false);
      message.error("Unable to delete template.")
    }
  };
  const handleDelete = (id) => {
    deleteTemplate(id)
  };
  const commonColumns = [
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Template name",
      dataIndex: "template_name",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Download",
      dataIndex: "template_file",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handlePdfDownload(record.template_file)}
          >
            <FaDownload />
          </Button>
        </Space>
      ),
    },
  ];
  
  const templateColumns = [...commonColumns,
    {
      title: "Delete",
      dataIndex: "template_file",
      render: (_, record) => (
        <Space>
          <Button type="" onClick={() => handleDelete(record.id)}>
            <MdDelete />
          </Button>
        </Space>
      ),
    },
  ];
  
  const masterTemplateColumns = [...commonColumns];
  

  return (
    <>
      <div className=" text-black pb-3 px-2 h-[60px] flex items-center justify-between">
        <span>
          <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">Templates</h1>
        </span>
        <>
          <Button type="primary" onClick={showModal}>
            Add Templates
          </Button>
          <Modal
            open={isModalOpen}
            title="Add Template"
            onCancel={onCancel}
            footer={[
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                loading={isFormLoading}
              >
                Submit
              </Button>,
            ]}
          >
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Template Name"
                    name="template_name"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter template name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Deacription" />
                  </Form.Item>
                </Col>
                <Row gutter={16}>
                  <Col>
                    <Form.Item
                      label="Select File"
                      name="template_file"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => e.fileList}
                      className="mr-2 attachment-input"
                    >
                      <Input
                        type="file"
                        accept=".doc, .docx"
                        onChange={(e) => setFilename(e.target.files[0])}
                        className="form-control"
                        required
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Modal>
        </>
      </div>
      <Spin spinning={isLoading} tip="Loading...">
        <Table
          dataSource={templateData && templateData}
          columns={templateColumns}
          pagination={false}
          bordered
          className="mb-4 template-table"
          scroll={{ x: "max-content" }}
        />
      </Spin>

      {(localStorage.getItem("user_role") === "legalteam" || localStorage.getItem("user_role") === "legalhead") && <>
      <span>
        <h1 className="mt-1 mr-4 text-3xl font-bold font-inter mb-2">
          Master Templates
        </h1>
      </span>
      <Spin spinning={isLoading} tip="Loading...">
        <Table
          dataSource={masterTemplateData && masterTemplateData}
          columns={masterTemplateColumns}
          pagination={true}
          bordered
          className="mb-4 template-table"
          scroll={{ x: "max-content" }}
        />
      </Spin>
      </>}
    </>
  );
}

export default MasterTemplate;
