import "./App.css";
import "./index.css";

import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Login from "./pages/auth/Login";
import Auth from "./components/Auth";
import Signup from "./pages/auth/Signup";
import DemoRequest from "./pages/auth/DemoRequest";
import Reset from "./pages/auth/Reset";
import CreateUser from "./pages/user/CreateUser";
import NoMatch from "./components/NoMatch";
import CreateCompany from "./pages/company/CreateCompany";
import ActiveUsers from "./pages/user/ActiveUsers";
import InactiveUsers from "./pages/user/InactiveUsers";
import AntSidebar from "./components/sidebar/AntSidebar";
import ResetComponent from "./components/forms/ResetForm";
import CompanyDashboard from "./pages/company/companyDashboard";
import ActiveCompanies from "./pages/company/activeCompanies";
import InactiveCompanies from "./pages/company/inactiveCompanies";
import UserDetail from "./pages/user/UserDetail";
import CompanyDetail from "./pages/company/CompanyDetail";
import { connect } from "react-redux";
import UnderNegotiation from "./pages/contracts/UnderNegotiation";
import UnderExecution from "./pages/contracts/UnderExecution";
import FreshRequest from "./pages/contracts/FreshRequest";
import AllRequests from "./pages/contracts/AllRequests";
import ExecutedContracts from "./pages/contracts/ExecutedContracts";
import TransactionClosed from "./pages/contracts/TransactionClosed";
import DataFetchingOnClickWithBearer from "./editor/button";
import InitiateRequest from "./pages/contracts/CreateRequest";
import SettingsPage from "./pages/Settings";
import CompanyAnalytics from "./pages/company/CompanyAnalytics";
import ContractDetailComp from "./pages/contracts/ContractDetail";
import Index from "./pages/contracts/third_party/Index";
// import ContractEdit from "./pages/contract_edit/ContractEdit";
import ForgotPassword from "./pages/auth/ForgotPassword";
import jwt_decode from "jwt-decode";
import { message } from "antd";
import AuditTrail from "./pages/dashboard/auditTrail";
import DashboardLayout from "./components/DashboardLayout";
import MasterTemplate from "./pages/masterTemplate/MasterTemplate";
import ClauseTemplate from "./pages/clauseTemplate/clauseTemplate";
import MyComponent from "./syncFusion/SyncDummy";

const INACTIVITY_TIMEOUT = 1200000; // 20 minutes in milliseconds
message.config({
  duration: 2,
});
function App(props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const isAdminRoute = window.location.pathname.startsWith("/admin");

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        startRefreshInterval();
      } else {
        startRefreshInterval(5 * 60 * 1000); // 5 minutes
      }
    };

    const startRefreshInterval = (
      intervalDuration = 4 * 60 * 1000 + 30 * 1000
    ) => {};
    if (isAdminRoute) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }
    if (isAdminRoute) {
      startRefreshInterval();
    }

    return () => {
      if (isAdminRoute) {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      }
    };
  }, [isAdminRoute]);

  useEffect(() => {
    const handleRouteChange = () => {
      // Your token decoding logic here

      if (isAdminRoute && !token && !localStorage.getItem("refresh_token")) {
        message.error("Unauthorized access");
        localStorage.clear();
      } else if (token) {
        const userInfo = jwt_decode(token);
        // Your token decoding logic here
      }
    };
    window.addEventListener("popstate", handleRouteChange);

    handleRouteChange();
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [navigate, token]);

  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  useEffect(() => {
    if (token) {
      const handleInteraction = () => setLastActiveTime(Date.now());
      window.addEventListener("mousemove", handleInteraction);
      window.addEventListener("keydown", handleInteraction);

      return () => {
        window.removeEventListener("mousemove", handleInteraction);
        window.removeEventListener("keydown", handleInteraction);
      };
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="auth" element={<Auth />}>
          <Route index path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="reset_password" element={<Reset />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="demo-request" element={<DemoRequest />} />
        </Route>
        <Route path="admin" element={<AntSidebar />}>
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<CompanyDashboard />} />
            <Route path="audit-trail" element={<AuditTrail />} />
          </Route>

          <Route exact path="analytics" element={<CompanyAnalytics />} />
          <Route path="template" element={<MasterTemplate />} />
          <Route path="clause" element={<ClauseTemplate />} />

          <Route path="create_user" element={<CreateUser />} />
          <Route path="create_company" element={<CreateCompany />} />

          <Route path="active_users" element={<ActiveUsers />} />
          <Route path="active_users/:pk" element={<UserDetail />}></Route>

          <Route path="active_companies" element={<ActiveCompanies />} />
          <Route path="active_companies/:pk" element={<CompanyDetail />} />

          <Route path="inactive_users" element={<InactiveUsers />} />
          <Route path="inactive_users/:pk" element={<UserDetail />}></Route>

          <Route path="inactive_companies" element={<InactiveCompanies />} />
          <Route path="inactive_companies/:pk" element={<CompanyDetail />} />

          <Route path="reset_password" element={<ResetComponent />} />

          <Route path="create_request" element={<InitiateRequest />} />
          <Route path="initiate_request" element={<InitiateRequest />} />

          <Route path="under_negotiation" element={<UnderNegotiation />} />
          <Route
            path="under_negotiation/:pk"
            element={<ContractDetailComp />}
          />

          <Route path="under_execution" element={<UnderExecution />} />
          <Route path="under_execution/:pk" element={<ContractDetailComp />} />

          <Route path="transaction_closed" element={<TransactionClosed />} />
          <Route
            path="transaction_closed/:pk"
            element={<ContractDetailComp />}
          />

          <Route path="executed" element={<ExecutedContracts />} />
          <Route path="executed/:pk" element={<ContractDetailComp />} />

          <Route path="contracts" element={<AllRequests />} />
          <Route path="contracts/:pk" element={<ContractDetailComp />} />

          <Route path="fresh_request" element={<FreshRequest />} />
          <Route path="fresh_request/:pk" element={<ContractDetailComp />} />

          <Route path="create_company" element={<CreateCompany />} />

          <Route path="settings" element={<SettingsPage />}></Route>
          <Route path="user/:pk" element={<UserDetail />} />
          <Route path="company/:pk" element={<CompanyDetail />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
        <Route path="edit/:pk" Component={DataFetchingOnClickWithBearer} />
        <Route path="edit-document/:pk" Component={MyComponent} />
        <Route path="contracts/riceived_agreement/" Component={Index} />
      </Route>
    </Routes>
  );
}
const mapStateToProps = (state) => ({ authdata: state.authReducer });
export default connect(mapStateToProps)(App);
