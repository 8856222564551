import React, { useState } from "react";
import { Modal, Table, Button, Checkbox } from "antd";
import { limitWords } from "../../utils/utils";
import {Tooltip} from "antd";

const MasterTemplateModal = ({ visible, onCancel, onSelect, data, masterTempData, okText, loading }) => {
  const [selectedId, setSelectedId] = useState(null);

  const columns = [
    {
      title: "Template Name",
      dataIndex: "template_name",
      key: "template_name",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedId === record.id}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
    },
  ];

  //   const data = [
  //     { id: 1, templateName: 'Template 1' },
  //     { id: 2, templateName: 'Template 2' },
  //     // Add more templates as needed
  //   ];
  const [selectedData, setSelectedData] = useState(null);

  const handleCheckboxChange = (record) => {
    setSelectedId(selectedId === record.id ? null : record.id);
    setSelectedData(selectedId === record.id ? null : record);
  };

  const handleSave = () => {
    // Do something with the selected ID
    onSelect(selectedData); // Pass the selected data to the callback

    // Close the modal

    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title="Choose A Template"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} loading={loading && loading}>
        {okText ? okText : "Send"}
        </Button>,
      ]}
    >
     <span>
        <h3 className="mt-1 mr-4 text-xl font-bold font-inter mb-2">
          Templates
        </h3>
      </span>
      <Table columns={columns} dataSource={data} rowKey="id" />

      <span>
        <h3 className="mt-1 mr-4 text-xl font-bold font-inter mb-2">
          Master templates
        </h3>
      </span>
      <Table columns={columns} dataSource={masterTempData} rowKey="id" />
    </Modal>
  );
};

export default MasterTemplateModal;
