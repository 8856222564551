import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./pages/Errorboundry";
import { BrowserRouter } from "react-router-dom";
import store from "./app/store";
import { Provider } from "react-redux";
import "./custom-theme.less";
import { refreshAccessToken } from "./api/api";

const isAdminRoute = window.location.pathname.startsWith("/admin");

const root = ReactDOM.createRoot(document.getElementById("root"));

const setupTokenRefresh = async () => {
  try {
    await refreshAccessToken(localStorage.getItem("refresh_token"));
  } catch (error) {
    console.error("Failed to refresh access token:", error);
  }
};
// Set interval to log "okay" every 3 seconds
if (isAdminRoute) {
  setInterval(setupTokenRefresh, 4 * 60 * 1000 + 30 * 1000);
}

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <App className="scroll-smooth" />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
