import React from "react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import { documentServerURL } from "../utils/Constant";

function MyDocumentEditor(props) {
  return (
    <div style={{ height: "100vh" }}>
      <DocumentEditor
        id="docxForComments"
        documentServerUrl={documentServerURL}
        config={props.config}
        height="100%"
        width="100%"
      />
    </div>
  );
}

export default MyDocumentEditor;
