import { redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { message } from "antd";
import axios from "../utils/axios.js";
import { API_URL } from "./Constant";

export async function requireAuth() {
  if (!localStorage.getItem("loggedin")) {
    // User is not logged in, redirect to the login route
    // window.location.href = "/auth/login?message=You must log in first."; // Replace '/login' with your actual login route
    return redirect("/auth/login?message=You must log in first.");
  }
}

export async function requireSuperAdmin() {
  if (!localStorage.getItem("loggedin")) {
    // User is not logged in, redirect to the login route
    window.location.href = "/auth/login?message=You must log in first."; // Replace '/login' with your actual login route
    // return redirect("/auth/login?message=You must log in first.")
  }
}

export async function superAdminAuth() {
  if (!localStorage.getItem("user_role") === "superadmin") {
    // User is not logged in, redirect to the login route
    window.location.href = "/auth/login?message=You must log in first."; // Replace '/login' with your actual login route
    // return redirect("/auth/login?message=You must log in first.")
  }
}
export const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
};
export const handlePdfDownload = (link, errorMessageShown, setErrorMessageShown) => {
  if (link) {
    window.open(`${link}`, "_blank");
    // Reset error message state
    setErrorMessageShown(false);
  } else {
    // Show error message only if it hasn't been shown already
    if (!errorMessageShown) {
      message.error("Document does not exist.");
      setErrorMessageShown(true);

      // Automatically reset error message state after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        setErrorMessageShown(false);
      }, 5000);
    }
  }
};

export async function extractUserInfoFromToken(token) {
  try {
    const userInfo = jwt_decode(token);
    const userId = userInfo.user_id;
    const userRole = userInfo.role;
    const userName = userInfo.name;
    const companyId = userInfo.company_id;
    const userEmail = userInfo.user_email;

    localStorage.setItem("user_id", userId);
    localStorage.setItem("user_role", userRole.toLowerCase());
    localStorage.setItem("user_name", userName);
    localStorage.setItem("company_id", companyId);
    localStorage.setItem("user_email", userEmail);

    try {
      if (companyId) {
        const res = await axios.get(API_URL + `/company/${companyId}/get/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res) {
          localStorage.setItem("currency", res?.data?.currency);
        }
      }
    } catch {}

    return userInfo;
  } catch (error) {
    console.error("Error decoding toke", error);
    return null;
  }
}

export function capitalizeAndRemoveUnderscores(sentence) {
  return sentence
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word && word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function convertToCamelCase(text) {
  // Remove spaces and convert to lowercase
  const camelCaseText = text.replace(/\s+/g, "").toLowerCase();

  // Capitalize the first letter
  return camelCaseText.charAt(0).toUpperCase() + camelCaseText.slice(1);
}

// export function limitWords(text, limit) {
//   const words = text && text.split(" " || ",");
//   if (words && words.length > limit) {
//     return words.slice(0, limit).join(" ") + "...";
//   }
//   return text;
// }
export const limitWords = (text, limit) => {
  if (text?.length > 18) {
    return text?.slice(0, 18) + "...";
  }
  return text;
};

export const formatDateAndTime = (modifiedAt) => {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(modifiedAt).toLocaleString("en-US", options);

  return formattedDate;
};
export function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export const findMaxIndex = (customField) => {
  let maxIndex = 0;

  for (const key in customField) {
    if (key.startsWith("field") || key.startsWith("value")) {
      const index = parseInt(key.slice(-1), 10);
      if (!isNaN(index) && index > maxIndex) {
        maxIndex = index;
      }
    }
  }

  return maxIndex;
};

