import React, { useState } from "react";
import { Button } from "antd";

function ButtonComp({
  link,
  text,
  color,
  bgColor,
  textColor,
  onClick,
  isDisabled,
  margin,
  height,
  width,
}) {
  const [hovered, setHovered] = useState(false);
  const buttonStyle = {
    height: height ? height : "3rem",
    width: width ? width : "15rem",
    backgroundColor: bgColor,
    color: textColor === "white" ? textColor : hovered ? "purple" : textColor,
    borderRadius: "10px",
    border: "1px solid #d8bfd8",
    transition: "color 0.3s", // Add transition for smooth color change
  };
  if (margin) {
    buttonStyle.marginRight = margin;
  }

  return (
    <Button
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => setHovered(false)}
      style={buttonStyle}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
}

export default ButtonComp;
