import React, { useState, useEffect } from "react";
import EditDocComponent from "./SyncButton.jsx";
import { useParams, useLocation } from "react-router-dom";
import axios from "../utils/axios.js";
import { API_URL } from "../utils/Constant.jsx";
import { message } from "antd";

message.config({
  top: 100,
});

const MyComponent = () => {
  const userName = localStorage.getItem("user_name");
  const [blobData, setBlobData] = useState(null);
  const token = localStorage.getItem("access_token");

  const { params } = useParams();
  const location = useLocation();
  const [agreementFile, setAgreementFile] = useState(null);
  const [hasBeenShown, setHasBeenShown] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const agreementFileParam = query.get("agreementFile");
    if (agreementFileParam) {
      setAgreementFile(JSON.parse(decodeURIComponent(agreementFileParam)));
    }
  }, [location.search]);

  const { pk } = useParams(); // Extract the 'id' from the URL

  const handleDocumentEditorClose = async (blob) => {
    setHasBeenShown(true);
    setBlobData(blob); // Set the Blob data in state or perform other actions
    // setShowDocumentEditor(false); // Close the DocumentEditor component
    const file = new File([blob], "document.docx", { type: blob.type });

    const formData = new FormData();
    formData.append("agreement_id", pk);
    formData.append("agreement_file", file);

    try {
      const response = await axios.post(
        API_URL + "/contract/save_agreement_file/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        message.open({
          type: "success",
          content: response.data.message,
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
      }
      setHasBeenShown(false);
    } catch (error) {
      message.open({
        type: "error",
        content: error.response.data.message,
        className: "custom-class",
        style: {
          marginTop: "2vh",
        },
      });
      setHasBeenShown(false);
      console.error("Error sending file to backend:", error);
    }
    const blobData = new Blob([JSON.stringify({ formData })], {
      type: "application/json",
    });

    // Use sendBeacon to send the data to the backend
    navigator.sendBeacon(API_URL + "/contract/save_agreement_file/", blobData);
  };
  return (
    <div>
      <EditDocComponent
        fileUrl={agreementFile && agreementFile}
        userName={userName}
        onClose={handleDocumentEditorClose}
        hasBeenShown={hasBeenShown}
      />
    </div>
  );
};

export default MyComponent;
