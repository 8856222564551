import axios from '../../utils/axios';
import React, { useState, useEffect } from "react";
import SuccessButton from "../../components/button/SuccessButton";
import { API_URL } from "../../utils/Constant";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Modal, Table, message } from "antd";
import { Link } from "react-router-dom";
import { formatIndianDate } from '../../utils/Constant';

function InactiveUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setIsloading] = useState(false);
  const token = localStorage.getItem("access_token");
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Are you sure you want to Activate this user?"
  );
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page_size: 100,
      is_active: "False",
    },
  };
  useEffect(() => {
    // Fetch user data from the API
    setIsloading(true);
    const fetchUsers = async () => {
      try {
        const response = await axios.get(API_URL + "/user/get/", config);
        setIsloading(false);
        setUsers(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchUsers();
  }, []);

  const columns = [
    {
      title: "S. No.",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`${record.id}`}>
          <Space>
            <button
              type="submit"
              className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
              View details
            </button>
          </Space>
        </Link>
      ),
    },
    {
      title: "User's Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "User's Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department) => (department ? department.name : ""),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Profile",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Activation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "Deactivation Date",
      dataIndex: "deactivation_date",
      key: "deactivation_date",
    },
    {
      title: "Deactivation Reason",
      dataIndex: "deactivation_reason",
      key: "deactivation_reason",
    },
    {
      title: "Activate",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <>
          <Space>
            <button
              onClick={() => showModal(record.id)}
              type="submit"
              className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
              Activate
            </button>
          </Space>
        </>
      ),
    },
    {
      title: "Assign Agreement",
      dataIndex: "age",
      key: "age",
      render: (text, record) => (
        <>
          <SuccessButton text="Click" />
        </>
      ),
    },
  ];

  const showPagination = users && users >= 10;

  const showModal = (userId) => {
    setUserId(userId);
    setOpen(true);
  };
  const handleOk = (userId) => {
    axios
      .put(
        `${API_URL}/user/${userId}/active/`,
        { active: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // Redirect to login page
          window.location.href = "/admin/inactive_users";
        } else {
          // throw new Error("Error updating user status.");
        }
      })
      .catch((error) => {
        message.error(error.response.data.error);
        setOpen(false);
      });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const userWithIds = users
    ? users.map((item, index) => ({
        ...item,
        index: index + 1,
        created_at: new Date(item.created_at).toLocaleDateString(),
        deactivation_date: item.deactivation_date
          ? new Date(item.deactivation_date).toLocaleDateString()
          : null,
      }))
    : [];

  return (
    <>
      {loading ? (
        <div className="text-center top-3 left-3">
          <LoadingOutlined style={{ fontSize: "5rem" }} />
        </div>
      ) : (
        <Table
          bordered
          dataSource={userWithIds}
          columns={columns}
          className="overflow-y-scroll"
          // scroll={{
          //   y: 530,
          // }}
          scroll={{ x: "max-content" }}
          // pagination={showPagination ? {} : false}
        />
      )}
      <Modal
        title="Deactivate"
        open={open}
        onOk={() => handleOk(userId)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default InactiveUsers;
