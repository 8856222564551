import React from "react";
import DemoRequestComponent from "../../components/forms/DemoRequest";
import LeftSide from "../../components/forms/LeftSide";
import { Row, Col } from "antd";


function DemoRequest() {
  return (
    <>
      <Row gutter={0} style={{ height: "100vh" }}>
        <Col xs={24} sm={12}>
          <LeftSide />
        </Col>
        <Col xs={24} sm={12}>
          <DemoRequestComponent />
        </Col>
      </Row>
    </>
  );
}

export default DemoRequest;
