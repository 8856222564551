import Chart from "react-apexcharts";

const ChartComponent = ({ chartData }) => {
    return (
      <div className={`${chartData.rounded} shadow-lg p-4 bg-white`}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          {...chartData.additionalProps}
        />
      </div>
    );
  };
  
export default  ChartComponent;