import React, { useState, useEffect } from "react";
import { signupCompany } from "../../api/api";
import SuccessModal from "../../components/modal/SuccessModal";
import axios from '../../utils/axios';
import { API_URL } from "../../utils/Constant";
import { message } from "antd";

function CreateCompany() {
  const [errorMessage, setErrorMessage] = useState("");
  // const [message, setMessage] = useState("");
  const navigation = false;
  const [name, setName] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [admin_email, setAdmin_email] = useState("");
  const [admin_contact_number, setAdmin_contact_number] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [created, setCreated] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const address = {
    country: parseInt(country),
  };
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  useEffect(() => {
    const flag = localStorage.getItem("setModal");
    if (flag && flag === "true") {
      setCreated(true);
      setModalVisible(true);
      localStorage.removeItem("setModal");
    }
  }, []);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data, res } = await signupCompany({
        name,
        admin_email,
        admin_contact_number,
        first_name,
        address,
      });
      if (res.status == 201) {
        setIsLoading(false);
        localStorage.setItem("setModal", true);
      }
    } catch (err) {
      setIsLoading(false);
      message.error(err.error[0]);
      return err.message;
    }
  };
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(API_URL + "/country/get/");
        setCountries(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchCountry();
  }, []);

  return (
    <div className="login-container form-box">
      <h1 className="login-text mt-6">Add a new Company</h1>
      {/* {message && <h3 className="red">{message}</h3>} */}
      {errorMessage && <h3 className="red">{errorMessage}</h3>}
      {localStorage.getItem("setModal") === "true" ? (
        <SuccessModal
          modal="true"
          message="Company created successfully. A link has been sent to admin's email to reset their password."
          link="/admin/dashboard"
        />
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit} className="login-form" replace>
        <label htmlFor="first_name">Name of User</label>
        <input
          name="first_name"
          type="text"
          // placeholder="Name of User"
          onChange={(e) => setFirst_name(e.target.value)}
          className="my-4"
        />
        <label htmlFor="">Company Name</label>
        <input
          name="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          // placeholder="Company Name"
          className="my-4"
        />
        <label htmlFor="">Email Address</label>
        <input
          name="admin_email"
          type="email"
          // placeholder="Email address"
          onChange={(e) => setAdmin_email(e.target.value)}
          className="my-4"
        />
        <label htmlFor="">Contact Number</label>
        <input
          name="admin_contact_number"
          type="text"
          onChange={(e) => setAdmin_contact_number(e.target.value)}
          // placeholder="Contact Number"
          className="my-4 border-b-2 border-red-600"
          pattern="[0-9]{10}"
          title="Please enter a 10-digit contact number."
        />
        <input hidden />
        <label htmlFor="">Country</label>
        <select
          id="country"
          name="country"
          placeholder="Country"
          onChange={handleCountryChange}
          // onChange={(e) => setCountry(e.target.value)}
          className="my-4 py-4 bg-slate-100"
        >
          <option value="">Select Country</option>
          {Object.entries(countries).map(([countryId, country]) => (
            <option key={countryId} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
        <button disabled={isLoading} className="my-6">
          {isLoading ? "Creating..." : "Add"}
        </button>
      </form>
    </div>
  );
}

export default CreateCompany;
