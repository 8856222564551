const ProgressBar = (props) => {
    const { bgcolor, completed, amount, name, srNo } = props;
  
    const containerStyles = {
      height: 25,
      width: "100%",
      backgroundColor: "#EBE8F7",
      borderRadius: 50,
    };
  
    const fillerStyles = {
      height: "100%",
      width: `${completed < 100 ? completed : 100}%`,
      backgroundColor: bgcolor,
      borderRadius: "inherit",
      textAlign: "right",
    };
  
    const labelStyles = {
      padding: 5,
      color: "white",
      fontWeight: "bold",
    };
  
    return (<>
    <div className="main-container flex flex-row justify-between" style={{ fontSize: '16px', letterSpacing:"1px" }}>
      {/* Left div */}
      <div className="left-div flex flex-row">
        <div className="sub-left-div mr-3">{srNo}</div>
        <div className="sub-left-div">{name}</div>
      </div>

      {/* Right div */}
      <div className="right-div">Rs.{amount}</div>
    </div>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {/* <span style={labelStyles}>{`${completed < 100 ? completed : 100}%`}</span> */}
        </div>
      </div>
    </>

    );
  };

export  default ProgressBar;