import React, { useState, useEffect } from "react";
import { Table, Button, Spin, message, Tooltip } from "antd";
import axios from "../../utils/axios";
import { API_URL } from "../../utils/Constant";
import { Link } from "react-router-dom";
import { capitalizeAndRemoveUnderscores } from "../../utils/utils";
import { limitWords } from "../../utils/utils";

import { formatDateAndTime } from "../../utils/utils";
import { handlePdfDownload } from "../../utils/utils";

function AuditTrail() {
  const token = localStorage.getItem("access_token");
  const [isLoading, setIsloading] = useState(false);
  let [auditTrail, setAuditTrail] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchAuditTrail = async (selectedStatus) => {
    try {
      if (selectedStatus) {
        config.params.status = selectedStatus;
      }
      const response = await axios.get(API_URL + "/dashboard/audit-trail/", config);
      setIsloading(false);
      setAuditTrail(response.data.data); // Update the state with the fetched user data
    } catch (error) {}
  };

  useEffect(() => {
    // Fetch user data from the API
    setIsloading(true);
    fetchAuditTrail();
  }, []);

  const [errorMessageShown, setErrorMessageShown] = useState(false);

  const handleViewClick = (link) => {
    // link
    //   ? window.open(`${link}`, "_blank")
    //   : message.error("Document does not exists.");
      if (link) {
        window.open(`${link}`, "_blank");
        // Reset error message state
        setErrorMessageShown(false);
      } else {
        // Show error message only if it hasn't been shown already
        if (!errorMessageShown) {
          message.error("Document does not exist.");
          setErrorMessageShown(true);
    
          // Automatically reset error message state after 5 seconds (5000 milliseconds)
          setTimeout(() => {
            setErrorMessageShown(false);
          }, 5000);
        }
      }
  };


  const columns = [
    {
      title: "Contract ID",
      dataIndex: "document_id",
      render: (documentID, record) => (
        <span><h1>{documentID && documentID}</h1></span>
      ),
    },
    {
      title: "Last modified User",
      dataIndex: "modified_by",
      render: (document, record) => (
        <span><h1>{document && document}</h1></span>
      ),
    },
    {
      title: "Last Modified Date and Time",
      dataIndex: "modified_at",
      render: (document, record) => (
        <span><h1>{document && formatDateAndTime(document)}</h1></span>
      ),
    },
    {
      title: "Agreement Type",
      dataIndex: "agreement_type",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Partner Name",
      dataIndex: "name_of_client",
      render: (document, record) => (
        <span><h1>{document && document}</h1></span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => capitalizeAndRemoveUnderscores(text),

    },
    {
      title: "Current Remarks",
      dataIndex: "remarks",
      // render: (text) => capitalizeAndRemoveUnderscores(text),
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Current Version",
      dataIndex: "agreement",
      render: (text, record) => (
        <>
          <Button
            // onClick={() => handlePdfDownload(record.current_agreement, errorMessageShown, setErrorMessageShown)}
            onClick={() => handleViewClick(record.current_agreement,  errorMessageShown, setErrorMessageShown)}
            type="submit"
            className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View
          </Button>
        </>
      ),
    },
    {
      title: "Previous Version",
      dataIndex: "agreement",
      render: (text, record) => (
        <>
          <Button
            onClick={() => handleViewClick(record.current_agreement, errorMessageShown, setErrorMessageShown)}
            type="submit"
            className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View
          </Button>
        </>
      ),
    },
    {
      title: "View History",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`/admin/contracts/${record.id}?referrer=audit-trail&from=listing&tab=details`}>
          <button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View history
          </button>
        </Link>
      ),
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {};

  return (
    <>
      <div className="bg-white">
        <div className=" text-black pb-3 px-2 flex items-center justify-between">
          <div className="flex flex-row justify-center align-middle">
            <span className="mt-3 ml-4">
              <h1
                className="mt-1 font-semibold font-inter text-3xl"

              >
                Audit Trail
              </h1>
            </span>
            {/* <div className="pl-4">
              <CustomSearch onSearch={handleSearch} />
            </div> */}
            {/* <div className="pl-4">
              <Select
                options={agreementStatus}
                onChange={handleSelectChange}
                size="medium"
                style={{
                  width: "14em",
                }}
                placeholder={"Select Status"}
                allowClear
              />
            </div> */}
          </div>
          {/* <Link to="/admin/create_auditTrail">
            <ButtonComp
              text="Raise New auditTrail"
              bgColor="#4E36A3"
              textColor="white"
              onClick={showExtraFieldModal}
            />
          </Link> */}
        </div>
        <Spin spinning={isLoading} tip="Loading...">
          <Table
            columns={columns}
            dataSource={auditTrail}
            onChange={onChange}
            scroll={{ x: "max-content" }}
          />
        </Spin>
      </div>
    </>
  );
}

export default AuditTrail;
