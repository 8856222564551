import DashboardCard from "../../components/dashboard/Card";
import { API_URL } from "../../utils/Constant";
import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { Table, Row, DatePicker, Button, Tooltip, Select } from "antd";
import Chart from "react-apexcharts";
import NewRequest from "../../assets/images/icon/NewRequest.png";
import Closed from "../../assets/images/icon/Closed.png";
import Executed from "../../assets/images/icon/Executed.png";
import Execution from "../../assets/images/icon/Execution.png";
import UnderNegotiation from "../../assets/images/icon/UnderNegotiation.png";
import { formatIndianDate } from "../../utils/Constant";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeAndRemoveUnderscores } from "../../utils/utils";
import { formatDateAndTime } from "../../utils/utils";
import { limitWords } from "../../utils/utils";
import ChartComponent from "../../components/chart/ChartComponent";

const { RangePicker } = DatePicker;
const { Option } = Select;

function CompanyDashboard() {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const role = localStorage.getItem("user_role");

  const [recentContracts, setRecentContracts] = useState([]);
  const [expireContracts, setExpireContracts] = useState([]);
  const [auditTrail, setAuditTrail] = useState([]);
  const [contractsCount, setContractsCount] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [customDateRange, setCustomDateRange] = useState(null);
  const [statusData, setStatusData] = useState([]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    // Perform any other actions based on the selected filter
  };

  const handleCustomDateChange = (dates) => {
    setCustomDateRange(dates);
    // Perform any other actions based on the custom date range
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page_size: "",
    },
  };

  const showPagination = false;

  const fetchContractsCount = async () => {
    try {
      const response = await axios.get(
        API_URL + "/dashboard/executed_agreement_count/",
        config
      );
      setContractsCount(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchContractsCount();
  }, []);

  const fetchAuditTrail = async () => {
    try {
      const response = await axios.get(
        API_URL + "/dashboard/audit-trail/",
        config
      );
      const top10auditTrail = response.data.data.slice(0, 13);

      setAuditTrail(top10auditTrail);
    } catch (error) {}
  };
  useEffect(() => {
    fetchAuditTrail();
  }, []);
  // const monthAbbreviations = contractsCount?.map(entry => entry?.month_name.slice(0, 3)) || [];
  const monthAbbreviations =
    Array.isArray(contractsCount) && contractsCount.length > 0
      ? contractsCount.slice(0, selectedFilter || 3).map((entry) => {
          const monthAbbrev = entry?.month_name?.slice(0, 3) || "";
          const yearLastTwoDigits = entry?.year?.toString().slice(-2) || "";
          return `${monthAbbrev} ${yearLastTwoDigits}`;
        })
      : [];

  // const dataCount = contractsCount?.map((entry) => entry?.count) || [];
  const dataCount =
    Array.isArray(contractsCount) && contractsCount.length > 0
      ? contractsCount
          .slice(0, selectedFilter || 3)
          .map((entry) => entry?.count || 0)
      : [];

  const maxDataValue = Math.max(...dataCount);
  const yaxisOptions = {
    max: maxDataValue < 10 ? 10 : maxDataValue,
  };

  const state = {
    series: [
      {
        name: "Executed Contrats",
        // data: [100,5,8],
        data: dataCount,
      },
    ],
    options: {
      chart: {
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        show: true,
        categories: monthAbbreviations,
      },
      yaxis: yaxisOptions,
      title: {
        text: "",
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      plotOptions: {
        bar: {
          distributed: false, // this line is mandatory
          endingShape: "false",
          colors: {
            ranges: [
              {
                from: 0,
                to: 20,
                color: "#A395DA",
              },
              {
                from: 21,
                to: 40,
                color: "#626993",
              },
            ],
          },
        },
      },
      colors: ["#A395DA", "#626993"],
    },
  };
  const ChartComponent = ({ chartData }) => {
    return (
      <div className={`${chartData.rounded} shadow-lg p-4 bg-slate-50`}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          {...chartData.additionalProps}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Contract ID",
      dataIndex: "document_id",
      key: "document_id",
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },

    {
      title: "Agreement",
      dataIndex: "agreement_type",
      key: "agreement_type",
      render: (text) => (
        <span style={{ fontSize: "12px", overflow: "hidden" }}>
          {limitWords(text, 6)}
        </span>
      ),
    },
    {
      title: "Partner Name",
      dataIndex: "name_of_client",
      key: "name_of_client",
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text) => capitalizeAndRemoveUnderscores(text),
    },
    {
      title: "Remarks",
      key: "remarks",
      dataIndex: "remarks",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link
          to={`/admin/contracts/${record.id}?referrer=dashboard&from=listing&tab=details`}
        >
          <button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View details
          </button>
        </Link>
      ),
    },
  ];

  const auditTrailColumns = [
    {
      title: "Contract ID",
      dataIndex: "document_id",
      key: "document_id",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Last Modified Date and Time",
      dataIndex: "modified_at",
      key: "modified_at",
      render: (text) => (
        <span className="font-xl text-center">{formatDateAndTime(text)}</span>
      ),
    },
  ];

  useEffect(() => {
    const fetchRecentContracts = async () => {
      try {
        const response = await axios.get(API_URL + "/contract/get/", config);
        const top10Contracts = response.data.slice(0, 10);
        setRecentContracts(top10Contracts);
      } catch (error) {}
    };

    fetchRecentContracts();
  }, []);

  useEffect(() => {
    const fetchExpireContracts = async () => {
      try {
        const response = await axios.get(
          API_URL + "/dashboard/to_expire_agreement/",
          config
        );
        const top10Contracts = response.data.data.slice(0, 10);
        setExpireContracts(top10Contracts);
      } catch (error) {}
    };

    fetchExpireContracts();
  }, []);

  const data = [];

  const topColumns = [
    {
      title: "Days Left",
      dataIndex: "expiry_in_days",
      key: "expiry_in_days",
      render: (text) => {
        // Assuming text is a string like "3 days, 0:00:00"
        const trimmedText = text.split(",")[0].trim();

        return (
          <span style={{ fontSize: "12px", paddingLeft: "15%" }}>
            {trimmedText}
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name_of_client",
      key: "name_of_client",
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    },
    // {
    //   title: "Date Of Raising Request",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>,
    // },

    {
      title: "Type",
      dataIndex: "agreement_type",
      key: "agreement_type",
      render: (text) => (
        <span style={{ fontSize: "12px", textAlign: "center" }}>{text}</span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "End Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (date) => formatIndianDate(date),
    },
  ];

  const allAgreementCounts = async () => {
    try {
      const response = await axios.get(
        API_URL + "/dashboard/agreement_count/",
        config
      );
      setStatusData(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    allAgreementCounts();
  }, []);

  const disabledDate = (current) => {
    const today = moment().startOf("day");
    const oneYearAgo = moment().subtract(1, "year").startOf("day");

    // Allow dates from today to one year ago (inclusive)
    return current && (current > today || current < oneYearAgo);
  };

  return (
    <>
      <div className="dashboard h-[100%]">
        <div className="mb-4 pr-8">
          <Row justify="space-around">
            <DashboardCard
              bgColor="bg-sky-200"
              icon={NewRequest}
              to="/admin/fresh_request/"
              text="Fresh Request"
              today={
                statusData &&
                statusData.today_count &&
                statusData.today_count.FRESH_REQUEST
              }
              total={
                statusData && statusData.count && statusData.count.FRESH_REQUEST
              }
            />

            <DashboardCard
              bgColor="bg-orange-100"
              icon={UnderNegotiation}
              to="/admin/under_negotiation/"
              text="Under Negotiation"
              today={
                statusData &&
                statusData.today_count &&
                statusData.today_count.UNDER_NEGOTIATION
              }
              total={
                statusData &&
                statusData.count &&
                statusData.count.UNDER_NEGOTIATION
              }
            />

            <DashboardCard
              bgColor="bg-teal-100"
              icon={Execution}
              to="/admin/under_execution/"
              text="Under Execution"
              today={
                statusData &&
                statusData.today_count &&
                statusData.today_count.UNDER_EXECUTION
              }
              total={
                statusData &&
                statusData.count &&
                statusData.count.UNDER_EXECUTION
              }
            />

            <DashboardCard
              bgColor="bg-lime-100"
              icon={Executed}
              to="/admin/executed/"
              text="Executed"
              today={
                statusData &&
                statusData.today_count &&
                statusData.today_count.EXECUTED
              }
              total={
                statusData && statusData.count && statusData.count.EXECUTED
              }
            />

            <DashboardCard
              bgColor="bg-red-200"
              icon={Closed}
              to="/admin/transaction_closed/"
              text="Transaction Closed"
              today={
                statusData &&
                statusData.today_count &&
                statusData.today_count.TRANSACTION_CLOSED
              }
              total={
                statusData &&
                statusData.count &&
                statusData.count.TRANSACTION_CLOSED
              }
            />
          </Row>
        </div>

        <div className="container mx-auto flex  flex-col md:flex-row">
          <div className="w-full md:w-1/2 pr-4 ">
            <div className="bg-white flex flex-col">
              <h1
                className="pl-4 font-semibold"
                style={{ fontSize: 20, paddingTop: "1%" }}
              >
                No. of Executed Contracts
              </h1>
              <div className="ml-4 flex flex-row items-left pr-4 mb-2 mt-2">
                <RangePicker
                  onChange={handleCustomDateChange}
                  value={customDateRange}
                  disabledDate={disabledDate}
                  className="pr-4"
                />
                <Select
                  defaultValue="3"
                  onChange={handleFilterChange}
                  style={{ width: "100px" }}
                  className="mx-4"
                >
                  <Option value="3">3 Months</Option>
                  <Option value="6">6 Months</Option>
                  <Option value="12">1 Year</Option>
                </Select>
              </div>
            </div>
            {/* <ChartComponent
              chartData={{
                options: state.options,
                series: state.series,
                additionalProps: { type: "bar", height: 300 },
                rounded: "rounded-b-lg",
              }}
            /> */}
            <div className={`${state.rounded} shadow-lg p-4 bg-slate-50`}>
              <Chart
                options={state.options}
                series={state.series}
                type="bar"
                height={300}
                {...state.additionalProps}
              />
            </div>
            {/* Other charts go here */}
          </div>
          <div className="w-full md:w-1/2 bg-white rounded-lg">
            <div className="rounded-t-lg">
              <h1
                className="pl-4 font-semibold"
                style={{ fontSize: 20, paddingTop: "1%" }}
              >
                Expiring Contracts
              </h1>
            </div>
            <Table
              dataSource={expireContracts}
              columns={topColumns}
              size="small"
              className="overflow-y-scroll mt-4"
              pagination={showPagination ? {} : false}
            />
          </div>
        </div>

        <div className="flex  flex-col md:flex-row mt-5">
          <div className="w-full md:w-2/3 mr-1 bg-white rounded-lg">
            <div>
              <h1
                className="pl-4 font-semibold"
                style={{ fontSize: 20, paddingTop: "1%" }}
              >
                Recent Contracts
              </h1>
            </div>
            <Table
              dataSource={recentContracts}
              columns={columns}
              className="overflow-y-scroll mt-4"
              // scroll={{
              //   y: 530,
              // }}
              scroll={{ x: "max-content" }}
              pagination={showPagination ? {} : false}
              size="small"
            />
          </div>
          <div className="w-full md:w-2/3 mr-1 bg-white rounded-lg">
            <div className="flex flex-row justify-between">
              <h1 className="ml-4 pt-2 font-semibold text-lg">Audit Trail</h1>
              <span className="mr-4 pt-2">
                <Button
                  type="primary"
                  onClick={() => {
                    navigate("audit-trail");
                  }}
                >
                  View All
                </Button>
              </span>
            </div>
            <Table
              dataSource={auditTrail}
              columns={auditTrailColumns}
              className="overflow-y-scroll mt-4"
              // scroll={{
              //   y: 530,
              // }}
              scroll={{ x: "max-content" }}
              pagination={showPagination ? {} : false}
              size="small"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDashboard;
