import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "../counterSlice";
import { applyMiddleware, combineReducers } from "redux";
import {thunk} from "redux-thunk";

import authReducer from "./reducers/authHandle";

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers here if needed
});

const store = configureStore({ reducer: rootReducer }, applyMiddleware(thunk));

export default store;

// export default configureStore({
//   reducer: {
//     counter: counterSlice,
//   },
// });
