import React, { useState, useEffect } from "react";
import MyDocumentEditor from "./editor";
import { useParams } from "react-router-dom";
import { API_URL } from "../utils/Constant";

const DataFetchingOnClickWithBearer = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState(false);
  const [error, setError] = useState(null);
  const accessToken = localStorage.getItem("access_token");

  const apiUrl = API_URL + "/editor/edit/";
  const bearerToken = accessToken;

  const fetchData = () => {
    setLoading(true);
    setError(null);

    const requestBody = {
      agreement_id: params.pk,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        setLoading(false);
        setData(responseData);
        setEditor(true);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return <div>{editor && <MyDocumentEditor config={data.data.cfg} />}</div>;
};

export default DataFetchingOnClickWithBearer;
