import { API_URL } from "../utils/Constant";
import axios from '../utils/axios';


// export async function loginUser(creds) {
//   const res = await fetch(API_URL + "/user/login/", {
//     method: "post",
//     body: JSON.stringify(creds),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
//   const data = await res.json();

//   if (!res.ok) {
//     const error = new Error(data.detail);
//     error.statusText = res.statusText;
//     error.status = res.status;
//     throw error;
//   }

//   return { data, res };
// }
export async function loginUser(creds) {
  try {
    const response = await axios.post(API_URL + "/user/login/", creds, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return { data: response.data, res: response };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data, statusText, status } = error.response;
      const customError = new Error(data.detail || statusText);
      customError.statusText = statusText;
      customError.status = status;
      throw customError;
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error('Error setting up the request');
    }
  }
}


export async function signupCompany(creds) {
  const res = await fetch(API_URL + "/company/create/", {
    method: "post",
    body: JSON.stringify(creds),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  if (!res.ok) {
    throw data;
  }

  return { data, res };
}

const token = localStorage.getItem("access_token");
export async function signupUser(creds, accessToken) {
  const res = await fetch(API_URL + "/user/signup/", {
    method: "post",
    body: JSON.stringify(creds),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await res.json();

  if (!res.ok) {
    const error = new Error(data.detail);
    error.statusText = res.statusText;
    error.status = res.status;
    throw data;
  }

  return { data, res };
}

export async function resetPassword(creds) {
  const res = await fetch(API_URL + "/user/set_password/", {
    method: "post",
    body: JSON.stringify(creds),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  return { data, res };
}
export async function forgotPassword(creds) {
  try {
    const response = await axios.post(`${API_URL}/user/${creds.email}/forgot_password/`, null, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return { data: response.data, res: response };
  } catch (error) {
    return { data: error.response.data, res: error.response };
  }
}

export async function sendPassword(creds) {
  try {
    const response = await axios.post(
      `${API_URL}/user/${creds.userId}/send-reset-password-link/`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { data: response.data, res: response };
  } catch (error) {
    return { error };
  }
}

export async function sendRequestDemo(creds) {
  try {
    const response = await axios.post(
      `${API_URL}/company/demo-request/`,
      creds.data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return { data: response.data, res: response };
  } catch (error) {
    return { error };
  }
}


export async function getConstants(creds) {
  const res = await fetch(API_URL + "/get_constants/", {
    method: "post",
    body: JSON.stringify(creds),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();

  if (!res.ok) {
    const error = new Error(data.detail);
    error.statusText = res.statusText;
    error.status = res.status;
    throw error;
  }

  return { data, res };
}

export const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await fetch(`${API_URL}/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to refresh access token');
    }

    const data = await response.json();

    // Update the access token in local storage
    localStorage.setItem('access_token', data.access);

    return data.access;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
};