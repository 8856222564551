import axios from "axios";
import { openNotification } from "./notification";
import { useNavigate } from "react-router-dom";

const instance = axios.create();

let unauthorizedNotificationShown = false;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const isAdminRoute = window.location.pathname.startsWith("/admin");
    if (isAdminRoute) {
    if (error.response && error.response.status === 401) {
      if (!unauthorizedNotificationShown) {
        unauthorizedNotificationShown = true;
        // Handle unauthorized access, e.g., redirect to login page
        openNotification(
          "error",
          "Unauthorized Access",
          "You do not have permission to access this page. Please log in again."
        );
        setTimeout(() => {
          window.location.href = "/auth/login";
        }, 3000);
      }
    } else if (error.response && error.response.status === 500) {
      // Handle Internal Server Error
      openNotification(
        "error",
        "Internal Server Error",
        "An internal server error occurred. Please try again later."
      );
      // You can choose to redirect or show a different error message based on your requirements
    }
  }
    return Promise.reject(error);
  }
);

export default instance;
