// RightSide.jsx
import React, { useState } from "react";
import { Form, Input, Button, Col, message } from "antd";
import Logo from "../../assets/images/logo.jpeg";
import { resetPassword, forgotPassword } from "../../api/api";

import { useNavigate, NavLink } from "react-router-dom";

const ForgotPasswordComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const email = values.email;
    try {
      setIsLoading(true);
      const { data, res } = await forgotPassword({
        email,
      });
      if (res.status === 200) {
        setIsLoading(false);
        // setModal(true);
        message.success("Email sent successfully.");
        navigate("/auth/login/");
      } else {
        message.error(data.error);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      return err.message;
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
  };

  return (
    <div
      style={{
        // backgroundColor: "#FFD700",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "80%", width: 400 }}>
        <div
          style={{
            textAlign: "left",
            marginBottom: 20,
            fontSize: "40px",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1>
            <span className="text-indigo-600">C</span>ore
            <span className="text-indigo-600">V</span>entum
          </h1>
        </div>
        <Col>
          <h1
            style={{
              lineHeight: "38.73px",
              fontSize: "40px",
              marginBottom: "2rem",
            }}
            className="font-bold"
          >
            Forgot Password 
          </h1>
        </Col>

        <Form onFinish={onFinish}>
          <Col>Email </Col>

          <Form.Item
            name="email"
            label=""
            style={{ marginBottom: 20 }}
            rules={[
              {
                required: true,
                message: "Please enter your email.",
              },
            ]}
          >
            <Input placeholder="john@gmail.com" required type="email" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              loading={isLoading}
              style={{ backgroundColor: "#4E36A3", borderColor: "#4E36A3" }}
            >
              {isLoading ? "Submitting.." : "Submit"}
            </Button>
          </Form.Item>
          <div
            style={{
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>
              Already have an account ? {""}
              <NavLink
                to={"/auth/login/"}
                style={{ color: "#4E36A3", fontWeight: "bold" }}
              >
                LOGIN
              </NavLink>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
