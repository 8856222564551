import React from "react";
import { Outlet } from "react-router-dom";

export default function DashboardLayout() {
  return (
    <div className="dashboard-wrapper">
      <div>
        <Outlet />
      </div>
    </div>
  );
}
