import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Col, message } from "antd";
import Logo from "../../assets/images/logo.jpeg";
import { NavLink, useNavigate } from "react-router-dom";
import { loginUser } from "../../api/api";
import { extractUserInfoFromToken } from "../../utils/utils";

const LoginComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const data = await loginUser({
        email: values.email,
        password: values.password,
      });
      if (data.data.access) {
        localStorage.setItem("access_token", data.data.access);
        localStorage.setItem("refresh_token", data.data.refresh);
        extractUserInfoFromToken(data.data.access);
        message.success("Login Successful", 3);
        setIsLoading(false);
        // navigate('/admin/dashboard');
        return (window.location.href = "/admin/dashboard");
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      message.error(err.message);
      setIsLoading(false);
      return err.message;
    }
  };

  return (
    <div
      style={{
        // backgroundColor: "#FFD700",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "80%", width: 400 }}>
        <div
          style={{
            textAlign: "left",
            marginBottom: 20,
            fontSize: "40px",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1>
            <span className="text-indigo-600">C</span>ore
            <span className="text-indigo-600">V</span>entum
          </h1>
        </div>
        <Col>
          <h1
            style={{
              lineHeight: "38.73px",
              fontSize: "40px",
              marginBottom: "2rem",
            }}
            className="font-bold"
          >
            Login
          </h1>
        </Col>

        <Form onFinish={onFinish}>
          <Col>Email Address</Col>

          <Form.Item name="email" label="" style={{ marginBottom: 20 }}>
            <Input type="email" placeholder="johndoe@gmail.com" required />
          </Form.Item>
          <Col>Password</Col>

          <Form.Item name="password" label="" style={{ marginBottom: 20 }}>
            <Input.Password placeholder="********" required />
          </Form.Item>

          <Col className="mb-6 flex flex-row">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox></Checkbox>
              <span className="pl-2">
                <p>Remember me</p>
              </span>
            </Form.Item>
          </Col>
          <Col>{/* <a href="#">Forgot password?</a> */}</Col>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              style={{ backgroundColor: "#4E36A3", borderColor: "#4E36A3" }}
              loading={isLoading}
            >
              Login
            </Button>
          </Form.Item>
          <div
            style={{
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>
              Don't have an account yet? {""}
              <NavLink
                to={"/auth/signup/"}
                style={{ color: "#4E36A3", fontWeight: "bold" }}
              >
                SIGN UP
              </NavLink>
            </p>
            <p>
              <NavLink
                to={"/auth/forgot-password/"}
                style={{ color: "#4E36A3", fontWeight: "bold" }}
              >
                Forgot Password
              </NavLink>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginComponent;
