import React from "react";
import SignupComponent from "../../components/forms/Signup";
import LeftSide from "../../components/forms/LeftSide";
import { Row, Col } from "antd";


function Signup() {
  return (
    <>
      <Row gutter={0} style={{ height: "100vh" }}>
        <Col xs={24} sm={12}>
          <LeftSide />
        </Col>
        <Col xs={24} sm={12}>
          <SignupComponent />
        </Col>
      </Row>
    </>
  );
}

export default Signup;
