import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Col, Row, InputNumber } from "antd";

const EditClauseOrderModal = ({
  visible,
  onCancel,
  data,
  onSubmit,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const updatedData = Object.keys(values).reduce((acc, key) => {
        const clauseId = parseInt(key.split("_")[2]);
        const clauseOrder = parseInt(values[key]);
        if (!isNaN(clauseOrder)) {
          const existingClause = acc.find(
            (item) => item.clause_id === clauseId
          );
          if (existingClause) {
            existingClause.clause_order = clauseOrder;
          } else {
            acc.push({ clause_id: clauseId, clause_order: clauseOrder });
          }
        }
        return acc;
      }, []);
      onSubmit(updatedData);
      form.resetFields();
    });
  };
  useEffect(() => {
    setInitialValues(
      data.reduce((acc, clause) => {
        acc[`clause_order_${clause.clause_id}`] = clause.clause_order;
        return acc;
      }, {})
    );
  }, [data]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };


  return (
    <Modal
      visible={visible}
      title="Preview Clause Order"
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
        >
          {isLoading ? "Submitting.." : "Submit"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        {data.map((clause) => (
          <Row key={clause.clause_id} gutter={24}>
            {/* <Col span={8} className="">
              <Form.Item
                label=" ID"
                name={`clause_id_${clause.clause_id}`}
                initialValue={clause.clause_id}
              >
                <Input disabled />
              </Form.Item>
            </Col> */}
            <Col span={8} className="">
              <Form.Item
                label=" Name"
                name={clause.clause_name}
                initialValue={clause.clause_name}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label=" Order"
                name={`clause_order_${clause.clause_id}`}
                initialValue={clause.clause_order}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  );
};

export default EditClauseOrderModal;
