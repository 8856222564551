import React, { useState } from "react";
import { Dropdown, Button, Space, message } from "antd";
// import 'antd/dist/antd.css';
import MonthItem from "./MonthItem";
import styles from "./FinancialYear.module.css";
import { FcOpenedFolder } from "react-icons/fc";

const FinancialYearFolder = ({ financialYear, isOpen, onToggle, handleMonthSelect }) => {

  const months = [
    {name: "Apr", value: "04"},
    {name: "May", value: "05"},
    {name: "Jun", value: "06"},
    {name: "Jul", value: "07"},
    {name: "Aug", value: "08"},
    {name: "Sep", value: "09"},
    {name: "Oct", value: "10"},
    {name: "Nov", value: "11"},
    {name: "Dec", value: "12"},
    {name: "Jan", value: "01"},
    {name: "Feb", value: "02"},
    {name: "Mar", value: "03"},
  ];

const splitYear = financialYear.split("-");
const financialYearStart = splitYear[0];
const financialYearEnd = splitYear[1];
const financialYearMonths = months.map(month => {
  const year = month.value >= "04" && month.value <= "12" ? financialYearStart : financialYearEnd;
  const monthNumber = month.value;
  return { name: month.name, monthNumber: monthNumber, year: year };
});
const items = financialYearMonths.map((month, index) => {
  return {
    label: month.name  + " " + month.year,
    key: month.monthNumber,
  };
})

const onClick = ({key}) => {
  console.log(key);
  const selectedMonth = financialYearMonths.find(month => month.monthNumber === key);
  if (selectedMonth) {
    const year = selectedMonth.year;
    console.log("Month:", key, "Year:", year);
    handleMonthSelect(key, year)
    // Do whatever you want with the year value
  } else {
    console.log("Month not found!");
  }
  };

  return (
    <div className={styles.container}>
      <Dropdown
        menu={{
          items,
          onClick: onClick,
        }}
      >
        <Space>
          <Button className="flex flex-row">
            <FcOpenedFolder size={18} style={{ marginRight: "8px" }} />
            {financialYear}
          </Button>
        </Space>
      </Dropdown>
    </div>
  );
};

export default FinancialYearFolder;
