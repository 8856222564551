import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { API_URL } from "../../utils/Constant";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { Badge, Descriptions, Input, Button, Form, message } from "antd";
import Select from "react-select";
import { IoMdArrowRoundBack } from "react-icons/io";
import { convertToCamelCase } from "../../utils/utils";
import { sendPassword } from "../../api/api";

function UserDetail() {
  const token = localStorage.getItem("access_token");
  const [form] = Form.useForm();

  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [department, setDepartment] = useState();
  const [constants, setConstants] = useState();
  const user_role = localStorage.getItem("user_role");
  const [editMode, setEditMode] = useState(false);
  const config = useMemo(() => {
    // Create and return the 'config' object
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pk: params.pk,
      },
    };
  }, []);
  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };
  const fetchDepartments = async () => {
    try {
      const response = await axios.get(API_URL + "/department/get/", config);
      const departmentOptions = response.data.map((item) => ({
        value: item.id, // Set the value to the email property
        label: item.name, // Set the label to a relevant property from your API data
      }));
      setDepartment(departmentOptions);
    } catch (error) {}
  };
  useEffect(() => {
    fetchDepartments();
  }, [config]);

  useEffect(() => {
    localStorage.setItem("gyrwmnqijpkl", "xvzndrpkloqy");
  });

  const fetchUsers = async () => {
    try {
      const response = await axios.get(API_URL + "/user/get/", config);
      setUser(response.data); // Update the state with the fetched user data
    } catch (error) {}
  };
  useEffect(() => {
    fetchUsers();
  }, [config]);

  const getConstants = async () => {
    try {
      const response = await axios.get(API_URL + "/get_constants/", config);
      setConstants(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getConstants();
  }, []);
  const onFinish = async () => {
    let values; // Declare values outside the try block
    values = await form.validateFields();
    const modifiedValues = {
      ...values,
      role: values.role
        ? values.role.value
        : user && user.role
        ? convertToCamelCase(user.role)
        : null,
    };
    console.log(modifiedValues);

    try {
      const modifiedConfig = { ...config };
      delete modifiedConfig.params;
      // Process the form values here
      const response = await axios.put(
        API_URL + `/user/${params.pk}/update/`,
        modifiedValues,
        modifiedConfig
      );
      console.log(response);
      console.log(response.status);
      if (response.status === 200) {
        message.success("User updated successfully");
        fetchUsers();
      } else {
        return;
        // throw new Error("Error updating user status.");
      }
    } catch (errorInfo) {
      return; // Stop further execution if validation fails
    }

    // Continue with the rest of your code
    setEditMode(false);
  };

  const handleSendPassword = () => {
    // Set loading to true when starting the request
    setLoading(true);

    sendPassword({ userId: user.id })
      .then((response) => {
        // Handle success
        message.success(response.data.message);
      })
      .catch((error) => {
        setLoading(false);
        // Handle error
      })
      .finally(() => {
        // Set loading to false when the request is complete (success or error)
        setLoading(false);
      });
  };

  return (
    <>
      {user ? (
        <>
          <div className="py-3 flex user-heading">
            <Link to={{ pathname: "/admin/settings/" }}>
              <IoMdArrowRoundBack size="40px" />
            </Link>
            <h1 className="font-bold text-[22px]">User Info</h1>
            <div className="flex user-info-heading">
              {!editMode && (
                <>
                  {/* <span>
                  <EditOutlined onClick={handleEdit} />
                </span> */}
                  {(user_role && user_role.toLowerCase() === "admin") ||
                  user_role.toLowerCase() === "superadmin" ? (
                    <span>
                      <EditOutlined onClick={handleEdit} />
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            encType="multipart/form-data"
          >
            <Descriptions title="" layout="vertical" bordered>
              <Descriptions.Item label="Name">
                {editMode ? (
                  <Form.Item
                    name={"first_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter First Name",
                      },
                    ]}
                    initialValue={user && user.first_name}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  user && user.first_name
                )}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Last Name">
                {editMode ? (
                  <Form.Item
                    name={"last_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last name",
                      },
                    ]}
                    initialValue={user && user.last_name}
                  >
                    <Input required />
                  </Form.Item>
                ) : (
                  user && user.last_name
                )}
              </Descriptions.Item> */}
              <Descriptions.Item label="Employee ID">
                {editMode ? (
                  <Form.Item
                    name={"employee_id"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter employee ID",
                      },
                    ]}
                    initialValue={user && user.employee_id}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  user && user.employee_id
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
              <Descriptions.Item label="Department">
                {/* {user.department ? user.department.name : ""} */}
                {user && user.department && user.department.name}
              </Descriptions.Item>
              <Descriptions.Item label="Role">
                {editMode ? (
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: false,
                        message: "Please enter role.",
                      },
                    ]}
                  >
                    <Select
                      // options={constants && constants.data.role.label}
                      options={
                        constants &&
                        constants?.data?.role?.map((country) => ({
                          // value: state.isoCode,
                          value: country.value,
                          label: country.lable,
                        }))
                      }
                      // onChange={handleReceiversEmailChange}
                      // defaultInputValue={user && user.role}
                      required
                    />
                  </Form.Item>
                ) : (
                  user && user.role
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Active Status" span={2}>
                {!user.is_active ? (
                  <Badge color="red" status="" text="Inactive" />
                ) : (
                  <Badge status="success" text="Running" />
                )}
              </Descriptions.Item>
              {!user.is_active && (
                <Descriptions.Item label="Deactivation Reason" span={2}>
                  {user.deactivation_reason}
                </Descriptions.Item>
              )}

              {/* 
              (user.is_active ? ""
              :
              <Descriptions.Item label="Deactivation Reason" span={2}>{user.deactivation_reason}</Descriptions.Item>
              ) */}
            </Descriptions>
            {user && user.business_head && (
              <>
                <Descriptions
                  title="Head's Details"
                  layout="vertical"
                  bordered
                  className="mt-3"
                >
                  <Descriptions.Item label="Name">
                    {user.business_head.first_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {user.business_head.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Role">
                    {user.business_head.role}
                  </Descriptions.Item>
                  <Descriptions.Item label="Employee ID">
                    {user.business_head.employee_id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Department">
                    {user.business_head.department && user.business_head.department.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {!user.is_active ? (
                      <Badge color="red" status="" text="Inactive" />
                    ) : (
                      <Badge status="success" text="Running" />
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}

            {!user.is_verified && !user.is_active && user_role && user_role === "admin" &&(
              <Button
                type="primary"
                onClick={handleSendPassword}
                className="mt-4"
                loading={loading}
              >
                Send Password Link
              </Button>
            )}
          </Form>
          {editMode && (
            <div className="mt-12 flex">
              <Button onClick={handleCancel} className="">
                Cancel
              </Button>
              <Button type="primary" onClick={onFinish} className="ml-12">
                Save
              </Button>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default UserDetail;
