import { API_URL } from "../../utils/Constant";
import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/DashboardLayout";
import SideBar from "../../components/sidebar/SideBar";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment } from "../../counterSlice";
import axios from '../../utils/axios';
import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Input, Table, Space, Button, Form } from "antd";
import { Link } from "react-router-dom";

function ActiveCompanies() {
  const count = useSelector((state) => state.counter.value);
  const [loading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState();
  const token = localStorage.getItem("access_token");
  const [open, setOpen] = useState(false);
  const [companyId, setCompanyId] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [reason, setReason] = useState([]);
  const [form] = Form.useForm();

  const [modalText, setModalText] = useState(
    "Are you sure you want to deactivate this company?"
  );

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page_size: 100,
      is_active: "True",
    },
  };

  const showModal = (companyId) => {
    setCompanyId(companyId);
    setOpen(true);
  };
  useEffect(() => {
    // Fetch user data from the API
    setIsloading(true);
    const fetchUsers = async () => {
      try {
        const response = await axios.get(API_URL + "/company/get/", config);
        setIsloading(false);
        setCompanies(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchUsers();
  }, []);
  const companyWithIds = companies
    ? companies.map((item, index) => ({
        ...item,
        index: index + 1,
        valid_to: new Date(item.valid_to).toLocaleDateString(),
      }))
    : [];

  const handleOk = (userId) => {
    axios
      .put(
        `${API_URL}/company/${userId}/active/`,
        { is_active: false },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // Redirect to login page
          window.location.href = "/admin/dashboard";
        } else {
          throw new Error("Error updating user status.");
        }
      })
      .catch((error) => {
        // Display error message
        console.error(error.message);
      });
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Name of Business Entity",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`${record.id}`} style={{ color: "blue" }}>
          {text}
        </Link>
      ),
    },
    {
      title: "Admin name",
      dataIndex: "name_of_admin",
      key: "name_of_admin",
    },
    {
      title: "Email",
      dataIndex: "admin_email",
      key: "admin_email",
    },
    {
      title: "Contact",
      dataIndex: "admin_contact_number",
      key: "admin_contact_number",
    },
    {
      title: "Subscription Validity",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Subscription Plan",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Total User",
      dataIndex: "user_count",
      key: "user_count",
    },
    {
      title: "Activation Date",
      dataIndex: "valid_to",
      key: "valid_to",
    },
    {
      title: "Action",
      dataIndex: "valid_to",
      key: "valid_to",
      render: (text, record) => (
        <>
          <Button
            onClick={() => showModal(record.id)}
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-red-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            Deactivate
          </Button>
          <Modal
            title="Add Remarks"
            open={open}
            onOk={() => handleOk(companyId)}
            onCancel={handleCancel}
          >
            <Form form={form} onFinish={handleOk}>
              <Form.Item
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "Please enter a value.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ),
    },
  ];
  const showPagination = companies && companies.length >= 10;

  return (
    <>
      {loading ? (
        <div className="text-center top-3 left-3">
          <LoadingOutlined style={{ fontSize: "5rem" }} />
        </div>
      ) : (
        <Table
          bordered
          dataSource={companyWithIds}
          columns={columns}
          className="overflow-y-scroll"
          // scroll={{
          //   y: 530,
          // }}
          scroll={{ x: "max-content" }}
          pagination={showPagination ? {} : false}
        />
      )}
      {/* <Modal
        title="Deactivate"
        open={open}
        onOk={() => handleOk(companyId)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        rules={[
          {
            required: true,
            message: "Please enter a value.",
          },
        ]}
      >
        <p>{modalText}</p>
        <Input
          placeholder="Enter Reason"
          className="mt-2"
          required
          onChange={(e) => setReason(e.target.value)}
        />
      </Modal> */}
    </>
  );
}

export default ActiveCompanies;
