import React from "react";
import { Row, Col,  } from "antd";
import LeftSide from "../../components/forms/LeftSide";
import ForgotPasswordComponent from "../../components/forms/ForgotPassword";

function ForgotPassword() {
  return (
    <>
      <Row gutter={0} style={{ height: "100vh" }} className="custom-row">
        <Col xs={24} sm={12}>
          <LeftSide />
        </Col>
        <Col xs={24} sm={12}>
          <ForgotPasswordComponent />
        </Col>
      </Row>
    </>
  );
}

export default ForgotPassword;
