import React, { useState, useRef, useEffect } from "react";
import { redirect, NavLink } from "react-router-dom";

export default function SuccessModal({ modal, message, link }) {
  const [showModal, setShowModal] = useState(false);
  const [created, setCreated] = useState("false");

  function handleClick() {
    setShowModal(false);
    localStorage.removeItem("setModal");
  }

  useEffect(() => {
    const flag = localStorage.getItem("setModal");
    if (flag && flag === "true") {
      setCreated(true);
      setShowModal(true);
      localStorage.removeItem("setModal");
    }
  }, []);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <h1>{message}</h1>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <NavLink to={link}>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleClick()}
                    >
                      OK
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
