import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../api/api";
import { message } from "antd";

function ResetComponent() {
  const [isLoading, setIsLoading] = useState("");
  const navigate = useNavigate();

  const navigation = false;
  const [modal, setModal] = useState(false);
  const [reset, setReset] = useState(false);
  const [password, setPassword] = useState();
  const [confirm_password, setConfirmPassword] = useState();

  const [modalVisible, setModalVisible] = useState(false);
  const params = new URLSearchParams(document.location.search);
  const uid = params.get("uid");
  const token = params.get("token");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useNavigate();
  const [created, setCreated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const strongPasswordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (!password.match(strongPasswordRegex)) {
      message.error(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    if (password !== confirm_password) {
      message.error("Passwords do not match.");
      return;
    }
    try {
      const { data, res } = await resetPassword({
        password,
        uid,
        token,
      });
      if (res.status == 200) {
        setIsLoading(false);
        setModal(true);
        message.success("Password Reset Successfully.");
        navigate("/auth/login/");
      } else {
        message.error(data.message);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      return err.message;
    }
  };

  useEffect(() => {
    const flag = localStorage.getItem("setModal");
    if (flag && flag === "true") {
      setCreated(true);
      setModalVisible(true);
      localStorage.removeItem("setModal");
    }
  }, []);

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div className="reset">
        <h1 className="login-text pb-8">Reset Password</h1>
        <form className="login-form" onSubmit={handleSubmit}>
          <label htmlFor="password">Password</label>
          <input
            name="password"
            type="password"
            className="my-4"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label htmlFor="confirm_password">Confirm Password</label>
          <input
            name="confirm_password"
            type="password"
            className="my-4"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <input hidden />
          <button type="submit" className="my-6">
            Reset
          </button>
        </form>
      </div>
    </>
  );
}

export default ResetComponent;
