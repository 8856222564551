import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectComponent = ({ value, options, onChange, isClearable, menuIsOpen }) => {
  return (
    <Select
    options={options}
    menuIsOpen={menuIsOpen}
    onChange={onChange}
    allowClear
    isClearable
    style={{width:"100%"}}
  >
    {options.map((option) => (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    ))}
  </Select>
  );
};

export default SelectComponent;
