import React from "react";

function NoMatch() {
  return (
    <div className="bg-green-200 h-[100vh] text-center pt-[50vh] text-2xl">
      <h2>Something went wrong</h2>
      <p>We apologize for the inconvenience. Please try again later.</p>
    </div>
  );
}

export default NoMatch;
