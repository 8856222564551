// RightSide.jsx
import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import Logo from "../../assets/images/logo.jpeg";
import { resetPassword } from "../../api/api";

import { useNavigate } from "react-router-dom";

const ResetComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const params = new URLSearchParams(document.location.search);
  const uid = params.get("uid");
  const token = params.get("token");
  const onFinish = async (values) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(values.password)) {
      // Show error if password doesn't meet the criteria
      message.error(
        "Password should contain at least one lowercase letter, one uppercase letter, one digit, and one special character and min 8 letters."
      );
      return;
    }
    const password = values.password;
    try {
      setIsLoading(true);

      const { data, res } = await resetPassword({
        password,
        uid,
        token,
      });
      if (res.status === 200) {
        setIsLoading(false);
        // setModal(true);
        message.success("Password Reset Successfully.");
        navigate("/auth/login/");
      } else {
        message.error(data.message);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      return err.message;
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div
      style={{
        // backgroundColor: "#FFD700",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "80%", width: 400 }}>
        <div
          style={{
            textAlign: "left",
            marginBottom: 20,
            fontSize: "40px",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1>
            <span className="text-indigo-600">C</span>ore
            <span className="text-indigo-600">V</span>entum
          </h1>
        </div>
        <Col>
          <h1
            style={{
              lineHeight: "38.73px",
              fontSize: "40px",
              marginBottom: "2rem",
            }}
            className="font-bold"
          >
            Reset Password
          </h1>
        </Col>

        <Form onFinish={onFinish}>
          <Col>Password </Col>

          <Form.Item
            name="password"
            label=""
            style={{ marginBottom: 20 }}
            rules={[
              {
                required: true,
                message: "Please enter your password.",
              },
            ]}
          >
            <Input.Password placeholder="********" required />
          </Form.Item>
          <Col>Confirm Password</Col>

          <Form.Item
            name="confirm"
            label=""
            style={{ marginBottom: 20 }}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match.");
                },
              }),
            ]}
          >
            <Input.Password placeholder="********" required />
          </Form.Item>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 24 }}
          ></Row>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              disabled={isLoading ? true : false}
              style={{ backgroundColor: "#4E36A3", borderColor: "#4E36A3" }}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetComponent;
