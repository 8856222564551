import { useState } from 'react';

const useClipboardAutoFill = (form) => {
  const [inputValue, setInputValue] = useState({});

  const handleInputClick = async (fieldPath) => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputValue((prevValues) => ({
        ...prevValues,
        [fieldPath.join('.')]: clipboardText || "" // Join the fieldPath to form a nested key
      }));
      if (form) {
        form.setFieldsValue({ [fieldPath]: clipboardText || "" }); // Update the specific form field
      }
      await navigator.clipboard.writeText('');

    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };


  const handleInputChanges = (e, fieldPath) => {
    const value = e.target.value;
    setInputValue((prevValues) => ({
      ...prevValues,
      [fieldPath.join('.')]: value // Join the fieldPath to form a nested key
    }));
    if (form) {
      form.setFieldsValue({ [fieldPath]: value }); // Update the specific form field
    }
  };

  return { inputValue, handleInputClick, handleInputChanges };
};

export default useClipboardAutoFill;
