// LeftSide.jsx
import React from "react";
import leftImage from "../../assets/images/left.png";
import { Button } from "antd";
import ButtonComp from "../button/ButtonComp";
import { useNavigate } from "react-router-dom";

const LeftSide = () => {
  const navigate = useNavigate();

  const textStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    fontSize: "24px",
    fontWeight: "bold",
    textShadow: "2px 2px 4px #000000",
  };
  function onClick() {
    navigate("/auth/demo-request/");
  }
  return (
    <>
      <>
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            margin: 0,
            position: "relative",
          }}
        >
          <img
            src={leftImage}
            alt="your-image"
            style={{ width: "100%", height: "100%" }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "40%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "white",
              fontSize: "24px",
              fontWeight: "bold",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            <div className="text-left">
              {/* <h1>Contract Management Tool</h1>
              <h3 className="text-sm mt-8">
                The Contract Management Tool (CMT) is a SaaS Platform developed
                by CoreVentum Consulting Private Limited specially designed to
                streamline and optimize the entire lifecycle of contracts within
                organizations. Acting as a centralized hub, it handles the
                creation, negotiation, execution, and management of various
                contracts—from customer and vendor agreements to employment
                contracts and legal documentation. This tool offers template
                creation options for different contract types, guaranteeing
                uniformity and precision across all contracts. Moreover, it
                supports secure execution through e-stamping and e-signatures,
                ensuring transparency in the process. CMT also provides in-depth
                reports on contract status, milestones, revenue based on
                products, performance metrics, and customized reports that aid
                management in making informed business decisions.
              </h3> */}
              <div className="left-container">
                <h1 className="left-heading">
                  About Contract Management Tool (CMT)
                </h1>
                <p className="left-subheading">
                  The CMT is a SaaS Platform developed and managed by CoreVentum
                  Private Limited.
                </p>
                <ul>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">Purpose:</strong> CMT
                    streamlines and optimizes contract lifecycles within
                    organizations.
                  </li>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">
                      Functionality:
                    </strong>{" "}
                    Acts as a centralized hub for contract creation,
                    negotiation, execution, and management.
                  </li>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">Coverage:</strong>{" "}
                    Manages various contracts including customer/vendor
                    agreements, employment contracts, legal documentation.
                  </li>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">
                      Template Creation:
                    </strong>{" "}
                    Offers template creation for different contract types
                    ensuring uniformity and precision.
                  </li>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">
                      Security Measures:
                    </strong>{" "}
                    Supports secure execution via e-stamping and e-signatures,
                    ensuring transparency.
                  </li>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">
                      Reporting Capabilities:
                    </strong>{" "}
                    Provides in-depth reports on contract status, milestones,
                    revenue based on products, and performance metrics.
                  </li>
                  <li className="left-list-item">
                    <span className="left-dot">•</span>{" "}
                    <strong className="left-bold-heading">
                      Customized Reports:
                    </strong>{" "}
                    Allows customized reports aiding management in making
                    informed business decisions.
                  </li>
                </ul>
              </div>
              <div style={{ textAlign: "left", marginTop: "4rem" }}>
                <ButtonComp
                  className=""
                  text={"Request a Demo"}
                  bgColor="white"
                  textColor={"#4E36A3"}
                  onClick={onClick}
                  height={"3em"}
                ></ButtonComp>
              </div>
              {/* <p className="mt-8">View plans</p> */}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default LeftSide;
