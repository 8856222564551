import React from "react";
import { Switch } from "antd";

const EditorSwitch = ({ isLoading, handleSwitchChange }) => {
  return (
    <Switch
      checkedChildren="All"
      unCheckedChildren="Editor"
      // defaultChecked
      className={`text-black ml-2 bg-slate-500`}
      onChange={(checked) => {
        handleSwitchChange(checked);
      }}
      loading={isLoading}
    />
  );
};

export default EditorSwitch;