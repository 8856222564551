import React, { useState } from "react";
import {
  Modal,
  Button,
  Spin
} from "antd";

const ClausesPreviewModal = ({
  visible,
  onCancel,
  okText,
  loading,
  handleSave,
  data
}) => {

  return (
    <Modal
      visible={visible}
      title="Caluse Preview"
      onCancel={onCancel}
      footer
    >
   {loading ? (
        <div style={{ textAlign: 'center' }}> {/* Center the spinner */}
          <Spin size="large" />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
      )}

    </Modal>
  );
};

export default ClausesPreviewModal;
