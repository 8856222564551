import React, { useEffect } from "react";
import { Button, message, Space } from "antd";

export const sucess = (content) => {
  message.success({ content });
};

export const logout = (content) => {
  message.success({ content });
};

export const loginError = (content) => {
  message.error({ content });
};

export const userSignup = (content) => {
  message.error({ content });
};

export const loginSuccess = (content) => {
  message.success({ content });
};

export const settingsUpdate = (content) => {
  message.success({ content });
};

export const settingsUpdateError = (content) => {
  message.error({ content });
};
