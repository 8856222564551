import React, { useRef, useEffect, useState } from "react";
import "./button.css";
import {
  DocumentEditorContainerComponent,
  Toolbar,
  WordExport,
  SfdtExport,
} from "@syncfusion/ej2-react-documenteditor";
import { registerLicense } from "@syncfusion/ej2-base";
import { Button } from "antd";
import { syncServiceUrl } from "../utils/Constant";
import { message } from "antd";
// const syncServiceUrl = process.env.REACT_SYNC_SERVICE_URL

DocumentEditorContainerComponent.Inject(Toolbar);
DocumentEditorContainerComponent.Inject(WordExport);
DocumentEditorContainerComponent.Inject(SfdtExport);

registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfVldXHxLflFyVWJYdV54fldBcC0sT3RfQFljTH5Rd0RjUH1bd3NVQw=="
);

function EditDocComponent({
  fileUrl,
  isEditable,
  userName,
  onClose,
  isSave = true,
  hasBeenShown,
}) {
  const containerRef = useRef(null);
  const [fileContent, setFileContent] = useState(null);

  const toolItem = {
    prefixIcon: "e-save",
    tooltipText: "Save",
    text: "Save",
    id: "Save",
    disabled: hasBeenShown,
  };
  const toolbarItems = [
    "New",
    "Open",
    toolItem,
    "Separator",
    "Undo",
    "Redo",
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    "TableOfContents",
    "Separator",
    "Header",
    "Footer",
    "PageSetup",
    "PageNumber",
    "Break",
    "InsertFootnote",
    "InsertEndnote",
    "Separator",
    "Find",
    "Separator",
    "Comments",
    "TrackChanges",
    "Separator",
    "LocalClipboard",
    "RestrictEditing",
    "Separator",
    "FormFields",
    "UpdateFields",
  ];

  const onCreated = () => {
    const container = containerRef.current;
    container.documentEditor.currentUser = userName;
    container.documentEditor.userColor = "#fff000";
    container.documentEditor.restrictEditing = false;

    const uploadDocument = new FormData();
    uploadDocument.append("DocumentName", fileUrl);
    const url = container.serviceUrl + "LoadDocument";
    const httpRequest = new XMLHttpRequest();
    httpRequest.open("POST", url, true);
    httpRequest.onreadystatechange = function () {
      if (httpRequest.readyState === 4) {
        if (httpRequest.status === 200) {
          container.documentEditor.open(httpRequest.responseText);
        }
      }
    };
    httpRequest.send(uploadDocument);

    setInterval(() => {
      updateDocumentEditorSize();
    }, 100);

    window.addEventListener("resize", onWindowResize);
  };

  const onSave = async () => {
    const container = containerRef.current;

    if (container && container.documentEditor) {
      const blob = await container.documentEditor.saveAsBlob("Docx");
      setFileContent(blob);
      return blob;
    } else {
      console.error("Document editor is not available.");
    }
  };

  const onWindowResize = () => {
    updateDocumentEditorSize();
  };

  const updateDocumentEditorSize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const container = containerRef.current;
    container && container.documentEditor.resize(windowWidth, windowHeight);
  };

  const handleClose = async () => {
    if (onClose && typeof onClose === "function") {
      const blob = await onSave();
      onClose(blob);
    }
  };

  const onSelectionChange = () => {
    const container = containerRef.current;
    if (container && container.documentEditor) {
      const selectedText = container.documentEditor.selection.text;
      if (selectedText) {
        navigator.clipboard &&
          navigator.clipboard
            .writeText(selectedText)
            .then(() => {
              console.log("Text copied to clipboard", selectedText);
              // setTimeout(message.success("Copied to clipboard"), 700)
              // if (!hasBeenShown) {
              //     message.success(selectedText + "Copied to clipboard")
              //     setHasBeenShown(true);

              //     // Reset the state to false after 5 seconds (5000 milliseconds)
              //     setTimeout(() => {
              //       setHasBeenShown(false);
              //     }, 700); // Adjust the time as needed
              //   }
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
            });
      }
    }
  };

  const onToolBarClick = (args) => {
    switch (args.item.id) {
      case "Save":
        handleClose();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleClose();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [fileContent]);

  return (
    <div>
      <div id="default_title_bar" className="e-de-ctn-title mb-14"></div>
      {/* {isSave && <Button onClick={handleClose} type='primary'>Save</Button>} */}
      <DocumentEditorContainerComponent
        id="container"
        ref={containerRef}
        height={"590px"}
        serviceUrl={`${syncServiceUrl}api/documenteditor/`}
        enableToolbar={true}
        created={onCreated}
        currentUser={userName}
        toolbarItems={toolbarItems}
        toolbarClick={onToolBarClick}
        selectionChange={onSelectionChange}
      />
    </div>
  );
}

export default EditDocComponent;
